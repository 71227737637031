import Master from "./MasterCss.css"
import Style from "./style.css"
import { Col, Row } from "antd";
import { Link,useLocation } from "react-router-dom";
import React,{ useState, useEffect } from "react";


const Header = () => {
    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = React.useState(null);
      
        React.useEffect(() => {
          let lastScrollY = window.pageYOffset;
      
          const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
              setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
          };
          window.addEventListener("scroll", updateScrollDirection); // add event listener
          return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
          }
        }, [scrollDirection]);
      
        return scrollDirection;
      };
      const scrollDirection = useScrollDirection();
     const pathname = useLocation().pathname;

    return (
        <>
        <Row>
            <Col span={24} className={`sticky ${ scrollDirection === "down" ? "-top-24" : "top-0"} h-24 bg-blue-200 transition-all duration-500 d_f a_i_c j_cent p_40_xxl header-parent`} style={{justifyContent:'center',padding:'24px'}}>
                <header className={"custom-header"}>
                    <ul className={"header-menu"}>
                        <li><Link 
                            reloadDocument
                            className={"navbar__link" + (pathname === "/" ? " active" : "")}
                            to="/">Myself</Link>
                        </li>
                        <li><Link 
                            reloadDocument
                            className={"navbar__link" + (pathname === "/portfolio" ? " active" : "")}
                            to="/portfolio">Portfolio</Link>
                        </li>
                        <li><Link 
                            reloadDocument
                            className={"navbar__link" + (pathname === "/blog" ? " active" : "")}
                            to="/blog">Blog</Link>
                        </li>
                        <li><Link
                            reloadDocument
                            className={"navbar__link" + (pathname === "/say-hi" ? " active" : "")}
                            to="/say-hi">Say Hi</Link>
                        </li>
                    </ul>
                </header>
            </Col>
        </Row>
        </>
    );
}

export default Header