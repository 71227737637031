import Master from "./MasterCss.css"
import {Col,Row,Image,Modal} from 'antd';
import Rajpng from "./img/raj-banner.png"
import Fullvideo from "./video/full-video.mp4";
import MagicImg from "./img/Magic_wand-default-white.gif";
import Micimge from "./img/mic.png";
import Miclisten from "./img/Mic-listen.png";
import Prortfolio from './img/protfolio-img.png';
import Mac from './img/mac-live-site.png';
import React,{ useLayoutEffect,useEffect, useRef,useState } from "react";
import { useParallax } from "react-scroll-parallax";
import Arrowimg from "./img/Double-arrow-white.png";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Styled, { styled } from "styled-components";
import useSound from "use-sound";
// import Sound from 'react-sound';
import {
  DoubleRightOutlined
} from '@ant-design/icons';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
  }

const HorizontalScrollCarousel = () => {
  const parallax = useParallax({
    easing: "ç",
    translateX: [0, 16],
  });
  const parallax2 = useParallax({
    easing: "easeOutQuad",
    translateX: [100, 0],
  });

  return (
    <div className={"banner-font-slide"}>
    <h1 ref={parallax.ref} className={"left-side-slider banner-font"}>
      Hello
    </h1>
    <h1 ref={parallax2.ref} className={"right-side-slider banner-font"}>
        I’m Raaj
    </h1>
    </div>
  );
};


const Glism = () => {
    // const parallax = useParallax({
    //   speed : 10,
    // });
    const parallax1 = useParallax({
      speed : 15,
    });
    // const parallax3 = useParallax({
    //   speed : 25,
    // });
  return(
    <Row justify="center" className={"glimpse-section"}>
    <Col span={13} xs={22} sm={22} md={13} lg={13} xl={13}>
        <p className="banner-text" style={{textAlign:'center',maxWidth:"672px",margin:"auto",paddingBottom:"24px",color:'#8F8F8F',fontSize:'16px',marginTop:'24px'}}>
        Stay tuned for upcoming videos on my design process, design system, and more! If you're interested in delving deeper, explore my detailed journey on the blog.
        </p>
        <button type="button" onClick={() => window.location.assign("blog")} style={{background:'transparent',border:'1px solid #fff',color:'#fff',marginTop:'0px'}} className="border-btn">Let's Go <DoubleRightOutlined /></button>
    </Col>
    <Col className="mt-5" span={19} xs={22} sm={22} md={19} lg={19} xl={19} style={{overflow:'hidden'}} ref={parallax1.ref}>
      <img style={{margin:'auto',marginTop:'24px'}} src={require('./img/live_sites_Birlaopus.jpg')} alt="Mac book live site"/>
    {/* </Col>
    <Col span={19} xs={22} sm={22} md={13} lg={13} xl={13} ref={parallax3.ref} style={{marginTop:'24px'}}> */}
      <h3 className={"section-heading"}>Glimpse</h3>
      <h4 className={"banner-sub-text"} style={{marginBottom:'2%'}}>of live sites</h4>
        <p className="banner-text" style={{textAlign:'center',maxWidth:"672px",margin:"auto",paddingBottom:"24px"}}>
        My recent project is <b>Birla Opus</b>. It was a 
    memorable collaboration, <b>solving design challenges</b>. Check out the live website 
    with just one click to experience the results!
        </p>
        <button type="button" onClick={() => window.open("https://www.birlaopus.com/", "_blank")} style={{background:'transparent',border:'1px solid #fff',color:'#fff',marginTop:'0px'}} className="border-btn">Let's Go <DoubleRightOutlined /></button>
    </Col>
  </Row>
  )
}

const MagicAnimation = () => {
  return(
    <div className="magic-img-div">
      <img  className="magic-img" src={MagicImg} alt="Magic Word"/>
    </div>
  )
}

function Home() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [showPopper,setShowPopper] = useState(false);
  const [popupText, setPopupText] = useState(false);
  const [playSound] = useSound(require('./music/confetti-audio.mp3'))
  const [playClose] = useSound(require('./music/magicwand_click.mp3'))
  const [playMicOn] = useSound(require('./music/mic_while_recording.mp3'));
  const DesignDiv = useRef();
  const DesignParent = useRef();

  const Container = useRef();
    const Wrapper = useRef();

 
  const scaleParallax = useParallax({
    easing: "easeOutQuad",
    scale: [1.5, 1],
  });

  const {
    transcript,
    listening,
    finalTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  const Speechedrbuton = () => {
    if(listening){
      playMicOn();
      if(SpeechRecognition.startListening){
        return (<div style={{margin: "10px auto",display: "grid",width: "fit-content",cursor: "pointer",justifyItems: "center",justifyContent: "center",alignItems: "center"}} onClick={SpeechRecognition.stopListening}><img src={Miclisten} alt="Mic Icon"/><span style={{color:'#fff',fontSize: '16px'}}>Say Now..</span></div>)
      }else{
        return (<div style={{margin: "10px auto",display: "flex",width: "fit-content",cursor: "pointer"}} onClick={SpeechRecognition.startListening}><img src={Micimge} alt="Mic Icon"/></div>)
      }
    }else{
      return (<div style={{margin: "10px auto",display: "flex",width: "fit-content",cursor: "pointer"}} onClick={SpeechRecognition.startListening}><img src={Micimge} alt="Mic Icon"/></div>)
    }
  }

  const SpeecbButton = !(browserSupportsSpeechRecognition) ? '' : <Speechedrbuton />;
  const HandleChange = (event) => {
      setValue(event.target.value);
  }

  useEffect(() => {
    const anim = gsap.fromTo(DesignDiv.current,{autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0});
    ScrollTrigger.create({
      trigger: DesignParent.current,
      start : "top center",
      animation: anim,
      toggleActions: 'play none none none',
    });
    if(finalTranscript){
      var divHeight = document.querySelectorAll("#showpopper").offsetHeight;
      console.log(divHeight);
      setOpen(!open);
      playSound();
      setTimeout(() => {
        playClose();
        setShowPopper(true);
      },5000)
    }
    const timeId = setTimeout(() => {
      setOpen(false)
    }, 5000)
    return() =>{
      setOpen(false);
      anim.revert();
      clearTimeout(timeId);
    }
  },[finalTranscript]);


  const HandleSiteMagic = () => {
    setOpen(true);
    setPopupText(true);
    var divHeight = document.querySelectorAll("#showpopper").offsetHeight;
    setTimeout(() => {
      playClose();
      setShowPopper(true);
      HorizontalScroll();
      setOpen(false);
    },5000)
  }

  const ModelContent = () => {
    if(finalTranscript == "Aabra Ka Daabra"){
      return (
        <>
        <h4>Abracadabraaaaaaaa...</h4>
        </>
          
      )
    }else{
      if(popupText){
        return (
          <>
          <h4>Abracadabraaaaaaaa...</h4>
          </>
            
        )
      }else{
        return(
          <>
          <p>Your magical word is 'Unique' 😁, but still, I can help you say it</p>
          <h4>Abracadabraaaaaaaa...</h4>
          </>       
        )
      }
    }
  }

  const[mobie, setMobie] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
        const width = window.innerWidth;
        if(width < 768){
          setMobie(true);
        }else{
          setMobie(false);
        }
    };
    window.addEventListener('load', handleScroll);
    return () => {
      window.removeEventListener('load', handleScroll);
    };
  },[]);

  function HorizontalScroll(){
    const t2 = gsap.to(Wrapper.current, {
      y: (-320) * 0.6 + "px",
      duration: .1,
      ease: "none",
  });
  ScrollTrigger.create({
    trigger: Container.current,
    start : !showPopper ? "top top" : "top center",
    end: () => -(-320) * 1 + "px",
    pin: true,
    animation:t2,
    scrub:1,
    invalidateOnRefresh:true,
    id: "ScrollAnimationTrigger",
    // markers:true
  });
  return () => {
    t2.revert();
  };
}
  
  useEffect(() => {
    return () => {
      HorizontalScroll();
    }
    },[]);

    const [mobileAnimation, setMobileAnimation] = useState(false);

    useEffect(() => {
      window.addEventListener("resize", () => {
        const width = window.innerWidth;
        if(width < 768){
          setMobileAnimation(true);
        }
      })
      window.addEventListener("load", () => {
        const width = window.innerWidth;
        if(width < 768){
          setMobileAnimation(true);
        }
      })
      return () => {
        window.removeEventListener("resize", () => {
          const width = window.innerWidth;
          if(width < 768){
            setMobileAnimation(true);
          }
        })
        window.removeEventListener("load", () => {
          const width = window.innerWidth;
          if(width < 768){
            setMobileAnimation(true);
          }
        })
      }
    },[]);

    const parallax = useParallax({
      easing: "easeOutQuad",
      rotateX : [0,-70],
      translateY: [0, -25],
      disabled: mobileAnimation
    });

    const parralexYcontent = useParallax({
      easing: "easeOutQuad",
      translateY: [0, -156],
      disabled: mobileAnimation
    })


    const mobilePopup = () => {
      setMobie(false);
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAnimation, setModalAnimation] = useState("slide-up");
    const [modalImg,setmodalImg] = useState("");

    function close() {
      setModalAnimation("slide-down");
      setTimeout(() => {
          setIsModalOpen(false);
          // Sets it for the next time so it slides up when called
          setModalAnimation("slide-up");
      }, 600);
  }

  function ModalOpen(popimg){
      setmodalImg(popimg);
      setIsModalOpen(true);
  }
      return (
        <>
         {isModalOpen && (
                    <div className={`modal-overlay`} onClick={() => close()}>
                        <div className='modal-container'>
                        <div className={`modal-content ${modalAnimation}`}>
                        {/* <img src={require('./img/Close_fill_popup.png')} onClick={() => close()} className='close-icon'/>
                        <img src={require('./img/Traffic_Lights.png')} className='traficlights' /> */}
                            <div className="feedbackModalHeader">
                                <img
                                    src={require(`./img/${modalImg}`)}
                                    alt=""
                                    className="dislikeBtnNonFilled"
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                )}
        <Modal
        title=""
        open={mobie}
        okButtonProps={{ disabled: true,block: false,style:{display:"none"} }}
        cancelButtonProps={{ disabled: true,block: false,style:{display:"none"}}}
        width={"80%"}
        className="mobile-screen-modal">
          <img style={{margin: "auto"}} src={require('./img/Info_mobile.png')} className="mobile-img" alt=""/>
          <h4>
          Hey buddy, this website is specially made for DESKTOP,
          </h4>
          <p>
           But still you can view in mobile,.
          </p>
          <button onClick={mobilePopup} className="mobile-btn">Continue here</button>
        </Modal>
   <Modal
        title=""
        open={open}
        okButtonProps={{ disabled: true,block: false,style:{display:"none"} }}
        cancelButtonProps={{ disabled: true,block: false,style:{display:"none"}}}
        width={"80%"}
        className="custom-modal"
      >
        <div className="modal-text">
          <img src={require('./img/Magicwand_black.gif')} alt="Magic Word" style={{margin: "auto"}}/>
          <ModelContent />
        </div>
      </Modal>
    <Row className={"home-page-banner-section"}>
      <Col span={24}>
        <HorizontalScrollCarousel />
        <Row justify="center" className={"banner-inner-section"}>
          <Col xs={24} sm={24} md={24} lg={7} xl={7} className={"d_f a_i_c j_cent"} style={{justifyContent:'center'}}>
                <img src={Rajpng} alt="Raaj Protfolio Banner Image"/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className={"banner-text-parent"}>
              <p className={"banner-text"}>
                I’m a Self thought & passionate UI/UX designer with 6+ years of experience in mobile, tab and web applications. Check out my work and say “HI/VANAKAM 🙏🏻”  to get in touch
              </p>
          </Col>
          </Row>
      </Col>
    </Row>

      {/* innovation section */}
      <Row justify="center" className={"Innovation-section"}>
        <Col span={19}>
          <h3 ref={scaleParallax.ref} className={"section-heading"}>Looooooook!</h3>
          <h4 className={"banner-sub-text black-gradeiant"}>What I innovated</h4>
        </Col>
        <Col span={13} xs={24} sm={24} md={13} lg={13} xl={13}>
            <video 
            style={{shadowOpacity: 1,shadowColor: '#fff'}}
            width={"100%"} autoPlay muted loop 
            className={"full-video"}
            src={Fullvideo}
            type="video/mp4" 
            controls={false} />
            {/* <Button shape="circle" block classNames={"border-btn"}>Explore</Button> */}
            <button type="button" className="border-btn" onClick={() => window.location.assign("/innovation")}>Explore <DoubleRightOutlined /></button>
        </Col>
      </Row>

      {/* Speech Reg */}
      {!showPopper ? <Row justify="center" className={"speech-section"}>
        <Col span={19}>
          <MagicAnimation />
            <h3 className="magics-head">
                Magical moments
            </h3>
            <input type="hidden" placeholder="Speak here" className="magic-input" onChange={HandleChange} value={finalTranscript} readOnly/>
            <h3 className="magics--sub">
                Press below Mic icon & Say <span>“Abracadabra”</span>
            </h3>
            {SpeecbButton}
        </Col>
        <Col span={19} className={"create-drawer"}>
          <img src={Arrowimg} alt="Mac live site" className="mac-img" onClick={HandleSiteMagic} />
        </Col>
      </Row> :  <MagicOpenSection id="showpopper">
        <img src={require("./img/Magic_success.gif")} className="magic-poppers" alt="Magic Poppers" />
        <div className="bg-opacity"></div>
          <img src={require("./img/Magicwand_Gradient.gif")} style={{margin:"auto"}} />
          <h4 className="magics--heading">Magical moments</h4>
          <div className="clip-first">
            <p>
            In the second level iteration, I anticipate that your ideas will<br/>
                surpass my expectations, reaching a level beyond my current thinking.
            </p>
            <br/>
            <span>
            CEOs from two distinct companies shared identical feedback.
            </span>
          </div>
          <div className="clips-promoters">
            <div className="clips-wrapper">
                <div className="clip-promoter">
                  <img className="img-left" src={require("./img/arrow-up.png")} alt="arrow icon" />
                By Dinesh.<br/>CEO of Beeyondzero
                </div>
                <div className="clip-promoter">
                <img className="img-right" src={require("./img/arrow-up-1.png")} alt="arrow icon" />
                By BalaManikandan.<br/>CEO of Exalcas
                </div>
            </div>
          </div>
          <div className="divider">
              <img src={require("./img/curve.png")} alt="" style={{margin: "25px auto"}} />
          </div>
          <div className="clip-second">
          <p>
          Performed thorough UX research for the ARANxt project at Exalca,<br/> 
          undertaking tasks such as interviews and persona development without<br/>
           prior information on user personas.
            </p>
            <br/>
            <br/>
            <span>
            Discovered a new understanding of myself upon finishing the Udemy design course.
            </span>
          </div>
    </MagicOpenSection>
    }
      
     
      
      <section style={{overflow:'hidden',marginBottom: "-1px"}}>
        
      <Row justify="center" className={"protfolio-section"} style={{overflow:'hidden'}}>
      <Col span={19}>
          <h3 className={"section-heading"}>One Portfolio</h3>
          <h4 className={"banner-sub-text"}>What I've done</h4>
        </Col>
        <Col span={19} style={{margin: '20px 0px',perspective: '1000px',display:'flex',justifyContent:'center'}}>
        <img ref={parallax.ref} src={Prortfolio} alt="Magic Image" className={"magic-img"} style={{width:"700px"}}/>
        </Col>
        <Col span={13} xs={22} sm={22} md={13} lg={13} xl={13} ref={parralexYcontent.ref} className={"banner-btn-text"}>
            <p className="banner-text" style={{textAlign:'center',maxWidth:"672px",margin:"auto",paddingBottom:"24px"}}>
              This project holds a deep <b>emotional connection for me.</b> I was the sole designer who collaborated directly <b>with the CEO</b> and <b>led a team of 20+ developers</b> when I had just over 2 years of experience.<br /><br />
               Working on the <b>ARANxt project</b> was truly <b>unique experience.</b> Wanna know more !
            </p>
            <button type="button" onClick={() => ModalOpen('Aranxt_image.jpg')} style={{background:'transparent',border:'1px solid #fff',color:'#fff',marginTop:'0px'}} className="border-btn">Explore <DoubleRightOutlined /></button>
        </Col>
        <Col span={13} xs={22} sm={22} md={13} lg={13} xl={13} style={{marginBottom: "56px"}}>
            <h3 className={"section-heading p_5 p_25_mb p_25_sm p_25_xs"}>Overview</h3>
            <h4 className={"banner-sub-text"}>My daily routine</h4>
        </Col>
        {/* <Col span={19} style={{marginTop:'24px'}}>
        </Col> */}
        </Row>
        </section>

      <Row>
      {/* <video 
            style={{shadowOpacity: 1,shadowColor: '#fff'}}
            width={"100%"} autoPlay muted loop 
            src={RoutineVideo}
            type="video/mp4" 
            controls={false} /> */}
            <iframe width="100%" height="600" src="https://www.youtube.com/embed/6MuDo1j8vcA?si=PrhVYe-5m3bDiDz6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Row>
      <Glism />
      <Row justify="center" className={"about-raaj"} ref={DesignParent}>
        <Col span={19} xs={22} sm={22} md={13} lg={13} xl={13}>
          <h3 className={"section-heading"} ref={DesignDiv}>Designing</h3>
          <h4 className={"banner-sub-text black-gradeiant"}>Tomorrow’s experiences today</h4>
        </Col>
        <Col span={16} xs={22} sm={22} md={16} lg={16} xl={16} style={{background:'#fff'}}>
          <p className="abut-raaj-txt">
          I’m a <span>product designer</span> based out of bangalore.<br />
      Currently working at <span>Concentrix Catalyst</span>. You can follow my hobbies on <br />
      insta post & story highlights <span>@Raaj.ux</span>
          </p>
        </Col>
      </Row>
        </>
    );
}

export default Home;

const MagicOpenSection = styled.div`
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    padding: 40px 0px;
    .magic-poppers{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .bg-opacity{
      opacity: 0.16;
      background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .magics--heading{
      background: linear-gradient(90deg, #FA0006 9.64%, #2300F0 90.44%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 24px;
    }
    .clip-first{
      max-width: 930px;
      margin: auto;
      position: relative;
      text-align: center;
      background: url(${require('./img/clicppath-1.png')});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 46px;
      p{
        color: var(--Portfolio-Black, #1F1F1F);
        text-align: center;
        /* Desktop/Poppins/Regular 20 */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span{
        color: var(--Portfolio-Black, #1F1F1F);
        text-align: center;
        /* Desktop/Poppins/Light 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
    @media only screen and (max-width: 769px) {
      .clip-first{
         p{
        font-size: 14px;
      }
      span{
        font-size: 14px;
      }
    }
    }
    .clip-second{
      max-width: 930px;
      margin: auto;
      position: relative;
      text-align: center;
      background: url(${require('./img/clippath-2.png')});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 46px;
      p{
        color: var(--Portfolio-Black, #1F1F1F);
        text-align: center;
        /* Desktop/Poppins/Regular 20 */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span{
        color: var(--Portfolio-Black, #1F1F1F);
        text-align: center;
        /* Desktop/Poppins/Light 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
    .clips-promoters .clips-wrapper{
      display: grid;
      max-width: 600px;
      margin: auto;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;
      justify-items: center;
      gap: 24px;
      .clip-promoter{
        position: relative;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: center;
      }
      .img-right{
        position: relative;
        top: 0px;
        left: 193px;
      }
      .img-left{
        position: relative;
        top: 0px;
        left: -7px;
      }
      
    }
    @media only screen and (max-width: 769px) {
      .clips-promoters .clips-wrapper{  
        .img-left{
          display: none;
        }
        .img-right{
          display: none;
        }
      }      
    }
    `