import './App.css';
import { BrowserRouter as Router, Routes, Route,useLocation } from "react-router-dom";
import Home from './pages/Home';
import Newlayout from './pages/Newlayout';
import { ParallaxProvider } from "react-scroll-parallax";
import Portfolio from './pages/Portfolio';
import Blog from './pages/Blog';
import Sayhi from './pages/Sayhi';
import Innovation from './pages/Innovation';
import React, {
  useLayoutEffect,
} from "react";
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <ParallaxProvider>
    <Router>
    <Wrapper>
      <Routes>
        <Route path="/" element={<Newlayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/say-hi" element={<Sayhi />} />
          <Route path='/innovation' element={<Innovation />} />
        </Route>
      </Routes>
      </Wrapper>
    </Router>
    </ParallaxProvider>
  );
}

export default App;

