import {Col,Row} from 'antd';
import { useParallax } from "react-scroll-parallax";
import Styled, { styled } from "styled-components";
import FadeUp from './img/div.project-fade.png';
import Fadedown from './img/div.project-fade -down.png';
import Fadeleft from './img/div.project-fade-side2.png';
import Faderight from './img/div.project-fade-side.png';
import LifeImg from './img/life_of_raaj.png';
import React,{ useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from 'aos';
import 'aos/dist/aos.css';
if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
  }
// const HorizontalScrollCarousel = () => {
//     // const parallax = useParallax({
//     //     easing: [1, -0.40, 0, 1.34],
//     //     translateX: [0, -40],
//     // });
//     const Parralex = useRef();
  
//     return (
//     <Col xs={24} sm={24} md={24} lg={24} xl={24}>
//     <SmallText >
//             Embark on the Enchanting Journey of Thought Unveiling with My Blog!  <span>🌟🧠</span>
//       </SmallText>
//       </Col>
//     );
//   };
const Blog = () => {
    AOS.init();
    const Container = React.useRef();
    const Wrapper = React.useRef();
    const HeadContainer = React.useRef();
    const Slideer = React.useRef();
    const DesignParent = useRef();
    React.useEffect(() => {// t2.ScrollTrigger.refresh();
        if(window.innerWidth > 768){
            const tl = gsap.to(Wrapper.current, {
                x: -(Wrapper.current.offsetWidth - Container.current.offsetWidth) * 1.5 + "px",
                duration: .1,
                ease: "none",
            });
            ScrollTrigger.create({
                trigger: Container.current,
                start : "top top",
                end: () => (Wrapper.current.offsetWidth - Container.current.offsetWidth) * 1.5 + "px",
                pin: true,
                animation:tl,
                scrub:1,
                invalidateOnRefresh:true,
                // markers:true
            })
            return () => {
                tl.revert();
            };
        }
    },[Wrapper.current]);

    useEffect(() => {
        if(window.innerWidth > 768){ 
            const t2 = gsap.to(Slideer.current, {
                x: -(Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                duration: .1,
                ease: "none",
            });
            ScrollTrigger.create({
                trigger: HeadContainer.current,
                start : "top top",
                end: () => (Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                pin: true,
                animation:t2,
                scrub:1,
                invalidateOnRefresh:true,
                // markers:true
            })
            return () => {
                t2.revert();
            }
        }
    },[]);



    const ScaleParrllexvs = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    })

    // const ScaleParrllexvs1 = useParallax({
    //     easing: "easeOutQuad",
    //     scale: [1.5, 1],
    // })

    const ScaleParrallexLOR = useParallax({
        easing: "easeOutQuad",
        scale: [1.5,1.2],
    });

    const Opacity1 = useParallax({
        easing: "easeOutQuad",
        opacity: [.5, 0],  
        translateY: [0, 20],
    });
    const Opacity2 = useParallax({
        easing: "easeOutQuad",
        opacity: [.5, 0],  
        translateX: [0, -20],
    });
    const Opacity3 = useParallax({
        easing: "easeOutQuad",
        opacity: [.5, 0],  
        translateX: [0, 20],
    });
    const Opacity4 = useParallax({
        easing: "easeOutQuad",
        opacity: [.5, 0],  
        translateY: [0,-20],
    });
    const Opacity5 = useParallax({
        easing: "easeOutQuad",
        opacity: [.5, 0],  
    });


    
      const Introimg = ({img,text,smtext}) => {
          return(
            <div>
                <div className={"intro-Sec"}>
                    <img src={require(`${img}`)} className="intro-img" alt="Intro"/>
                    <div className="intro-cnt">
                        <p className="intro-text">{text}</p>
                        <p className="intro-pos">{smtext}</p>
                    </div>
                </div>
            </div>
          );
      }
    return (
        <>
        <section>
       <StyledBanner ref={HeadContainer}>
            <Row justify="center" className="sub-page-banner-sec">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='banner-sec-pdd'>
                    <BannerText className="sub-page-banner" >Narrative</BannerText>
                    <SmallText ref={Slideer}>
                            Embark on the Enchanting Journey of Thought Unveiling with My Blog!  <span>🌟🧠</span>
                    </SmallText>
                </Col>                
            </Row>
       </StyledBanner>
       </section>

        <LifeofRaaj>
            <img src={LifeImg} className="life-img" alt="Life of Raaj" ref={ScaleParrallexLOR.ref}/>
            <img src={FadeUp} className="fade-up fade-imgs" alt="Life of Raaj" ref={Opacity1.ref} />
            <img src={Fadeleft} className="fade-left fade-imgs" alt="Life of Raaj" ref={Opacity2.ref} />
            <img src={Faderight} className="fade-right fade-imgs" alt="Life of Raaj" ref={Opacity3.ref} />
            <img src={Fadedown} className="fade-down fade-imgs" alt="Life of Raaj" ref={Opacity4.ref} />
            <h4 ref={Opacity5.ref}>Life of Raaj</h4>
        </LifeofRaaj>

        <section>
        <Testimonial id="testimonial" ref={Container}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading">Heartfelt Voices</h3>
                    <h4 class="banner-sub-text">Accolades Received!</h4>
                </Col>
            </Row>
            <Row justify="center">
                <div className="testMonial">
                    <img src={require('./img/mic-vector.png')} alt="Testimonial" key={2} className="mic-img"/>
                    <img src={require('./img/side-star.png')} alt="Testimonial Satr" key={1} className="star-img"/>
                    <div className="testimonial-wrapper" ref={Wrapper}>
                    {/* [,,,,] */}
                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-1.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">Looks good Raj</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-1.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Dinesh</p>
                                    <p className="testi-company">Beeyondzero, CEO</p>
                                </div>
                            </div>
                    </div>  
                </div>

                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-2.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">Great Outputs Raj</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-2.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Balamanikandan</p>
                                    <p className="testi-company">Exalca, CEO</p>
                                </div>
                            </div>
                    </div>  
                </div>

                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-3.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">Especially Shiv - Loved it! Shiv (iOPEX, CEO)</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-3.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Harsha</p>
                                    <p className="testi-company">iOPEX, A.D</p>
                                </div>
                            </div>
                    </div>  
                </div>

                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-4.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">Super. Excellent work, Raj.,Super happy with the output</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-4.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Anand</p>
                                    <p className="testi-company">Concentrix, A.D</p>
                                </div>
                            </div>
                    </div>  
                </div>

                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-5.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">Looks Good.</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-5.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Vamsi Krishna</p>
                                    <p className="testi-company">Honeywell, Ad. S/w Engineer</p>
                                </div>
                            </div>
                    </div>  
                </div>

                <div className={"testimonial-col"}>
                        <div className="testi-div">
                            <img src={require('./img/testimonial/tp-6.png')} className="testi-prof" alt="Testimonial"/>
                            <img src={require(`./img/Rating.png`)} className="testi-img" alt="Testimonial"/>
                            <p className="testi-cnt">it was indeed good & smart work.Your dedication is commendable</p>
                            <div className="testi-owner">
                            <img src={require('./img/testimonial/testi-6.png')} className="testi-owner-img" alt="Testimonial"/>
                                <div>
                                    <p className="testi-name">Saugata</p>
                                    <p className="testi-company">Concentrix, Tech. Manager</p>
                                </div>
                            </div>
                    </div>  
                </div>


                    <div className={"testimonial-col"}>
                            <div className="testi-div">
                                <img src={require(`./img/happy-emoji.png`)} className="testi-img" alt="Testimonial"/>
                                <p className="testi-cnt">You could be next! 😉</p>
                            </div>  
                            </div>
                    </div>
                </div>
            </Row>
        </Testimonial>
        </section>

        <VibeSet>
            <Row justify="center">
            <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={ScaleParrllexvs.ref}>Vibe Set</h3>
                    <h4 class="banner-sub-text black-gradeiant">Unexpected Turns!</h4>
                    <p class="heading-sub-cnt mt-24">
                        Hey, I see you diving into my blog. 
                        How cool is that! Get ready for a vibe because we're about to 
                         <span> explore</span> what happens when the <span>last bench arrear guy becomes the topper in life</span>. Let's dive in! 🚀📖
                    </p>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Rapid Rewind</h4>
                        <p className="vibeset-cnt mt-24">
                            On <span>January 4, 1998, in Coimbatore,</span> Tamil Nadu, India, I came into this world. Growing up, I was your typical <span>last-bench kid from a middle-class family</span>. My early academic journey until the 9th grade was marked by continuous failures in multiple subjects. However, <span>my interests extended far beyond the confines of textbooks</span> during those years. 🌍🎒
                        </p>
                        <p className="vibeset-cnt mt-24">Despite academic setbacks, I discovered a remarkable memory power within me. I became the go-to <span>"Google map" for my family</span> and developed a strong penchant for indoor games. In the 8th grade, I even ventured into carom, participating in the district match in a double-player mode. Though I didn't emerge victorious, the experience fuelled my determination. In a moment of determination, I made a bet with my <span>P.T. Teacher, Ms. Rooba</span>—promising her biryani if I achieved at least a runner-up position the following year. 🏆🍛</p>
                        <p className="vibeset-cnt mt-24">Motivated by the bet, I dedicated myself to <span>daily summer practice.</span> 
The 9th-grade district match in <span>2011</span> took place in Coimbatore at Nehru Stadium. Against the odds, I secured the <span>runner-up position</span>, and true to her word, <span>Ms. Rooba treated me to biryani</span>. 🥳🏅</p>
                        <p className="vibeset-cnt mt-24">Anticipation lingered as I awaited the half-yearly English exam on <span>December 12, 2012.</span> The question in my mind was whether this journey would see a positive turn. To my relief, I performed well in the exam, marking a significant chapter in my ongoing narrative. The <span>world continued</span> beyond December 12, 2012, and so did my story. 📚✨</p>
                        <Introimg img="./img/intro/raaj-sekar.png" text="Mr. Rajasekar" smtext="Yeah it's me" />
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Life challenges</h4>
                        <p className="vibeset-cnt mt-24">
                        As mentioned earlier, I perceive <span>bets as challenges,</span> and my determination usually leads to victory. This mindset carried into my 10th grade when I wagered a smartphone from my parents on achieving above 80%. Eventually, <span>I secured 85.6%,</span> yet my parents didn't fulfil their end of the deal. In response, I staged a protest at home, refusing to eat on time, and more. 
                        </p>
                        <p className="vibeset-cnt mt-24">
                        This protest not only earned me the promised smartphone 
but also brought an <span>unexpected challenge—jaundice</span> at the beginning of 11th grade due to the food protest. 📱🏆
                        </p>
                        <p className="vibeset-cnt mt-24">
                        The jaundice progressed to a <span>critical stage,</span> prompting me to seek help from my cousin brother, <span>Mr. Anguraj</span>. In the early morning, we traveled from my hometown (Coimbatore) to Kerala to obtain necessary medication. Fortunately, after ten days of dedicated treatment, I <span>successfully recovered,</span> marking the end of this challenging chapter. 🌅💊🩹
                        </p>
                        <Introimg img="./img/intro/anguraj.png" text="Mr. Anguraj " smtext="Business man" />
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Design Transition</h4>
                        <p className="vibeset-cnt mt-24">
                        In <span>2013</span>, armed with my new smartphone, I <span>delved into the world of creativity</span> by installing the <span>"Picsart" app</span>. Exploring its features, I experimented with options like cloning and layer effects. 📱🎨
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During my 12th-grade summer break, my cousin and her friend, 
<span>Ms. Aishwarya,</span> planned to enroll in a summer course. Candidly, I joined the course with the ulterior motive of getting to know her friend. Unfortunately, Ms. Aishwarya didn't end up joining. Undeterred, my cousin and I decided to enroll in the <span>ATDP Course for Animation and Design at Apollo Education Institute,</span> Gandhipuram, Coimbatore. 🌐✨ 
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Initially drawn by the idea of picking up her friend, my passion for editing and design led me to genuinely embrace the <span>ATDP offline course</span>. As the course commenced, <span>my prior experience with "Picsart"</span> on my mobile app proved beneficial. I quickly developed expertise in tools like <span>Photoshop and Illustrator, happily immersing myself</span> in the world of graphic design at that age. 🎓💻🎨                        </p>
                        <Introimg img="./img/intro/ashwarya.png" text="Mrs. Aishwarya " smtext="Operation Manager." />
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">College diaries</h4>
                        <p className="vibeset-cnt mt-24">
                        Once more, fate brought me face to face with my cousin's friend, <span>Ms. Aishwarya,</span> in college, and so the story goes... 
<span>We found love blossoming between us.</span> 💑 
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During the period between the 1st and 2nd year, <span>I conducted a Photoshop workshop</span> within my college's internal department. It was during this workshop that I crossed paths with my classmate, <span>“Mr. Murali”,</span> also known as SMKR, who was juggling <span>entrepreneurship with part-time graphic design.</span> 🎨🖥️
                        </p>
                        <Introimg img="./img/intro/smkr.png" text="Mr. SMKR" smtext="Entrepreneurship" />
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Placement status</h4>
                        <p className="vibeset-cnt mt-24">
                        As mentioned before, I was a last bench student, and at one point, <span>I had one arrear</span>. Unfortunately, I couldn't clear that during my college years, <span>but after six months,</span> I successfully addressed and <span>passed</span> that lingering challenge. 🎓
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Shifting focus to placements, I <span>participated</span> in over <span>24 companies,</span> both through campus and drives. However, I faced hurdles in clearing the first-round aptitude tests. Amid these attempts, I secured a position with <span>ICICI Bank</span> during a college campus placement. Despite successfully navigating the group discussion, I opted to <span>decline the offer</span> as it <span>wasn't aligned</span> with <span>my passion for design.</span> 💼🖌️
                        </p>
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Journey (how it starts)</h4>
                        <p className="vibeset-cnt mt-24">
                        Throughout my college years, I sought <span>guidance</span> from a <span>local IT expert</span> named "Mr. Prabhu." Whenever I had a moment, I'd bombard him with questions, seeking direction on the <span>next steps in the field of design.</span> It was during one of these discussions that he <span>recommended</span> pursuing a career as a <span>UX designer.</span> At the time, I expressed my <span>disinterest in coding,</span> but he assured me that UI/UX <span>design involves</span> understanding <span>HTML and CSS</span> rather than extensive coding. 🖥️
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Post-college, faced with this new challenge, I dedicated myself to <span>learning these skills</span>. In those early days, I was <span>unfamiliar</span> with the concept of a <span>portfolio</span>. Consequently, I applied to numerous UI/UX positions without one and lacking some essential knowledge. Despite the hurdles, I persevered, refusing to lose <span>hope</span>. 🚀
                        </p>
                        <Introimg img="./img/intro/prabu.png" text="Mr. Prabhu" smtext="Sr. Java Developer," />
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Part 1 (My 1st Job)</h4>
                        <p className="vibeset-cnt">
                            In retrospect, it marked the beginning of <span>my first job</span> and a <div>significant learning journey.</div> 
                        </p>
                        <p className="vibeset-cnt mt-24">
                            At that time, my <span>interest</span> in film editing led me to participate in <span>short film shoots and editing.</span> Surprisingly, I found myself presented with an opportunity to be an <span>assistant director,</span> marking the magical start of a new chapter. 🎬 During <span>periods of unemployment,</span> I tirelessly sought job opportunities everywhere. It was during a film shoot that <span>I reached out to my cousin, “Mr. Jay Prakash”,</span> expressing my interest in Graphic/UX design-related roles. He posed a couple of questions regarding salary expectations and my passion. I conveyed that my happiness lay in securing a design job, and I was willing to forego a competitive salary for the first two years, emphasising my 
                            <span>deep passion for designing.</span> 🎨
                        </p>
                        <p className="vibeset-cnt mt-24">
                        He emphasised the <span>importance of self-learning</span> and referred me to his first company, <span>Beeyondzero</span>, despite its <span>small scale. “Mr. Dinesh,” the CEO,</span> graciously provided me the <span>opportunity to work as a UI UX designer.</span> 
                        </p>
                        <p className="vibeset-cnt mt-24">
                            The journey at my <span>first company was challenging – low salary and lack of support</span>  from my parents due to societal expectations. Nevertheless, I remained steadfast in <span>pursuing my three-year short-term goals and vision. 🚀</span>
                        </p>
                        <p className="vibeset-cnt mt-24">
                        On the design front, I <span>experienced</span> the joy of creating <span>my first logo, "Gomeat,"</span> which found its place in the <span>Play Store.</span> One of my <span>designs</span> even got selected in a <span>magazine.</span> Whenever I faced doubts, my <span>college friend and entrepreneur, 
“Mr. Murali,”</span> served as a reliable source to <span>clarify graphic design queries.</span> 💡
                        </p>
                        <p className="vibeset-cnt mt-24">
                        On the <span>personal front,</span> the struggle with a <span>meager income,</span> a <span>breakup,</span> and times of going <span>without meals</span> were prevalent. However, amidst these challenges, my colleague, <span>“Mr. Paramesh”,</span> generously bought me lunch many times, for which <span>I felt grateful.</span> In the midst of this struggle, I found a <span>lifetime mentor in “Mr. Vikash”,</span> who provided substantial support in my UI UX career and life. Learning from his experiences in developing <span>Android/iOS</span> apps 
was <span>truly remarkable.</span> 🌟
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Additionally, <span>“Mr. Sakthi”,</span> a front-end expert, played a crucial role in <span>expanding my knowledge of HTML, CSS, and JS,</span> emphasizing the <span>importance of portfolios.</span> As the first wave of <span>COVID-19</span> hit, <span>delays in payments for over six months</span> prompted my decision to switch. This period marked a memorable kickstart in my career, 
<span>shaping</span> my journey <span>forward</span>. 🚀
                        </p>
                        <div className={"col_3 col_1_lg videset-intro-split"}>
                            <Introimg img="./img/intro/Jay-Prakash.png" text="Mr. Jay Prakash" smtext="Sr. Java developer" />
                            <Introimg img="./img/intro/Dinesh.png" text="Mr. Dinesh " smtext="Beeyondzero, CEO" />
                            <Introimg img="./img/intro/Paramesh.png" text="Mr. Paramesh" smtext="Sr. Front End Develp." />
                        </div>
                        <div className={"col_2 col_1_lg videset-intro-split"}>
                            <Introimg img="./img/intro/Vikash.png" text="Mr. Vikash" smtext="Sr. Android & iOS Dev." />
                            <Introimg img="./img/intro/Sakthi.png" text="Mr. Sakthi" smtext="Sr. Front End Dev." />
                        </div>
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Part 2 (Hero mode ON)</h4>
                        <p className="vibeset-cnt mt-24">
                        In an overview, it marked <span>my second job</span> and a transformative journey from <span>zero to hero.</span> 🚀
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During the quarantine period, I contributed to a freelance project called <span>"chesspool,"</span> initiated by my another cousin, <span>“Mr. Mohan Raja,”</span> where I played a role in <span>UI/UX design.</span> 🛠️
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Simultaneously, I actively sought a job change due to a six-month salary hold caused by the first wave of the COVID-19 pandemic. A call from <span>“Mr. Vasu,” an HR at Exalca,</span>  via the <span>"Naukri" app</span> led me to undergo a successful machine test. Upon entering the final round, held within the <span>innovative environment of the Chill SEZ campus,</span> the startup experience was truly amazing. I completed the final round, impressing <span>“Mr. Balamanikandan,”</span> the <span>CEO of Exalca,</span> who, unfortunately, is no longer with us due to the second wave of COVID-19. 🌐
                        </p>
                        <p className="vibeset-cnt mt-24">
                        My first day at Exalca was memorable, as they handed me a set of <span>UX screens</span> right away. Despite the official work hours being from <span>9:30 AM to 6:30 PM, my dedication and passion</span>  drove me to <span>work overtime until 9 PM on my very first day</span>. This caught the attention of “HR Mr. Vasu and CEO Mr. Bala,” leading to my recognition as the "Star Performer of the Month" within a few months. 🌟
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Subsequently, <span>Mr. Bala entrusted me</span> with a significant project named <span>"ARANxt," a game-changing</span>  moment in my UX career. Although <span>unaware of UX research</span> initially, I conducted research using my <span>graphical knowledge</span>. This included feedback analysis, <span>user personal creation,</span> case studies, <span>in-person interviews,</span> live product studies, and <span>more.</span> 🧠
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During this time, I <span>reconciled</span> with my girlfriend, marking a <br /> <span>"singing in the rain"</span> moment. 
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Designing the <span>ARANxt</span> project was <span>truly incredible,</span> and I shared these <span>experiences</span> in a detailed case study, accessible here: <span>ARANxt Case Study</span>. Throughout, I received <span>numerous appreciations and rewards from Mr. Bala</span> for my overall <span>design contributions</span>. 🎉
                        </p>
                        <p className="vibeset-cnt mt-24">
                        One day, <span>Mr. Bala approached me</span> with a message, expressing interest in my transition to <span>Product Management and Analysis</span>—an encouraging moment. Due to the <span>second wave of COVID-19,</span> the company implemented <span>remote work</span> for all employees, and unfortunately, <span>we lost our CEO.</span> 🌧️
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Despite consistently progressing in my design work, the <span>appraisal discussion</span> after a few months <span>didn't align</span> with my <span>career mission</span>. Consequently, I <span>decided</span> to shift to a <span>different organisation.</span> 🔄
                        </p>
                        <div className={"col_3 col_1_lg videset-intro-split"}>
                            <Introimg img="./img/intro/Mohan-Raja.png" text="Mr. Mohan Raja" smtext="Chesspool, CEO" />
                            <Introimg img="./img/intro/Vasu.png" text="Mr. Vasu " smtext="Sr. HR" />
                            <Introimg img="./img/intro/Bala.png" text="Mr. Bala" smtext="Exalca, CEO (in hearts)" />
                        </div>
                        <div className="intro-cnt col_1_lg mt-24">
                            <div className="star-section col_2_lg">
                                <img src={require("./img/intro/raaj-star.png")} alt="star perfomence" />
                                <p className="star-text">Star<br/>
                                        Performer<br/>
                                        Moment</p>
                                        <span>🌟</span>
                            </div>
                        </div>
                    </div>
                    <div className="mtb-60">
                    <h4 className="inner-heading">Part 3 (Cameo Role)</h4>
                        <p className="vibeset-cnt mt-24">In summary, it marked <span>my third job</span> and a <span>cameo role at iOPEX.</span> 🌟</p>
                        <p className="vibeset-cnt mt-24">During the transition to this company, I underwent several interviews, with one <span>HR suggesting</span> I take a <span>Udemy</span> course. Despite being a self-taught UX designer and feeling my skills were by nature, I recognised the <span>importance of a portfolio</span>. Completing the Udemy course not only fulfilled this need but also <span>turned into a hobby</span>. 🎓🌱</p>
                        <p className="vibeset-cnt mt-24">
                    While <span>attempting</span> interviews with <span>ZOHO corporations twice,</span> I gained <span>valuable experience.</span> However, their underpaid offers based on my potential led me to seek opportunities elsewhere. The iOPEX interview process went exceptionally well, and the <span>final round,</span> an <span>in-person interview in Bangalore,</span> solidified my position. Post-interview, during my journey back home, a call from the iOPEX recruiter regarding the <span>package mismatch left me</span> on the <span>brink of rejection</span>. However, <span>after a quick consultation with the hiring manager</span>, I received a call <span>confirming my successful performance</span> and subsequent onboarding in the <span>Bangalore office.</span> 🏢🤝
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Initially <span>excited to collaborate</span> and learn from <span>other designers</span>, I encountered the challenge of being the <span>sole designer</span> in an <span>L3 company</span>. Despite this, I embraced the situation and continued happily in Bangalore. Unfortunately, due to a <span>project downturn</span>, the RMG team planned to transfer me from <span>Bangalore to Chennai</span>. Initially considering it a temporary project support location, I later learned it was to be my permanent location. Although not part of my initial plan, I <span>welcomed</span> the opportunity for <span>new experiences and exposure,</span> enjoying the challenging and delightful <span>two months in Chennai.</span> 🌆💼
                        </p>
                        <p className="vibeset-cnt mt-24">
                        However, the <span>lack of proper documentation for the location</span> change became a challenge. Despite multiple requests to the Chennai Branch <span>Reporting Manager and RMG person</span> for a <span>transfer letter,</span> the process faced delays. <span>Internal politics</span> within the management further complicated the situation, eventually leading to my <span>termination</span>. 📉🚧
                        </p>
                        <p className="vibeset-cnt mt-24">
                        With a <span>notice period of two weeks,</span> I reflected on my potential showcased in previous roles and concluded that this company did not value a <span>quality candidate</span>. This realization <span>self motivated</span> for what would become <span>my massive transition.</span> 💪🚀
                        </p>
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Part 4 (Present Journey )</h4>
                        <p className="vibeset-cnt mt-24">
                        In an overview, it marks <span>my fourth job</span> and represents the current chapter of <span>my life journey</span>. 📘
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Following the <span>fire incident in my previous organization,</span> I had a two-week window to exit. In those intense 14 days, I <span>accelerated</span> my job search on <span>Naukri</span>, focused on <span>portfolio creation,</span> completed machine test assignments, and expanded my <span>LinkedIn connections</span> at a <span>10x speed</span>. Despite it being Diwali week, I chose not to return to my native (Coimbatore) and instead stayed in a PG, <span>dedicating myself</span> to securing a <span>new opportunity</span>. 🚀
                        </p>
                        <p className="vibeset-cnt mt-24">
                        The efforts paid off, as I <span>happily received two job offers.</span> Although the first offer came from <span>Bosch</span>, I rejected it as it involved working as a <span>solo designer</span> with plans for transition into <span>development</span>. My dedication to design progression led me to <span>decline this offer.</span> 🙅‍♂️
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Simultaneously, I progressed through the eighth round at Concentrix and eventually cleared the <span>final 9th round</span>. Within three days of rejecting the Bosch offer, I received the <span>Concentrix offer</span>. Joining <span>Concentrix Catalyst fulfilled</span> a <span>dream of working</span> with a supportive, mentor-driven, and <span>creatively challenging team</span>. I continued my consistent work in design at this new workplace. 🌟
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During this period, I also happily <span>got married</span> to my longtime girlfriend, <span>overcoming</span> the challenges with <span>family support</span>. It marked a series of joyful moments, akin to a musical <span>"la la lalallaaa"</span> experience and back-to-back "singing in the rain" <span>butterfly moments.</span> 🎶☔
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Engaging in the <span>learning stage,</span> I developed a newfound <span>interest in reading.</span> A colleague <span>“Mr. Prakash Tuli“</span> shared valuable book <span>recommendations</span>, and I decided to read at least one book <span>to achieve my next goals</span>. Surprisingly, it transformed into a <span>hobby, with books like "Show Your Work, Steal Like an Artist and field guide to human centered design"</span> resonating personally. I've now completed over four books and have many more on my checklist, all while continuing to learn something new every day with my team. 📚🌈
                        </p>
                        <div className={"col_2 col_1_lg videset-intro-split"} style={{gap: "0px"}}>
                            <Introimg img="./img/intro/prakash.png" text="Mr. Prakash" smtext="Sr. UI UX Designer" />
                            <img src={require("./img/intro/Blog_Books.png")} style={{marginTop:"24px"}} className="life-img" alt="Life of Raaj" />
                        </div>
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Conclusion</h4>
                        <p className="vibeset-cnt mt-24">
                        Whenever uncertainty arises, my go-to sources for guidance are my cousin, <span>Mr. Arun, mentor Mr. Vikash, and 
my wife, Mrs. Aishwarya</span>. 🌟
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Given any opportunity, I make it a point to spend time with <span>successful individuals,</span> aiming to absorb and gain <span>insights</span> from their <span>experiences</span>. Embracing challenges and <span>proactively</span> addressing them has been a consistent approach for <span>me</span>. 💪
                        </p>
                        <p className="vibeset-cnt mt-24">
                        During free moments, I often listen to <span>podcasts</span> featuring <span>successful individuals</span>. This allows me to easily assimilate their experiences and <span>apply them to my own journey</span>. 🎧
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Throughout this path, I've encountered and learned from <span>6 to 8 personal projects,</span> including ventures such as business <span>startups</span>, offline <span>businesses</span>, and a <span>YouTube</span> channel with diverse content. While some may view <span>these as failures</span> , I consider them valuable experiences that <span>contribute to my growth</span>. I plan to implement these <span>lessons in my ongoing UX design journey</span>. 🚀 
                        </p>
                        <p className="vibeset-cnt mt-24">
                            <span>a few of my tech friends who support me with joy and enthusiasm.</span>
                        </p>
                        <div className={"col_3 col_1_lg videset-intro-split"}>
                            <Introimg img="./img/intro/Arun.png" text="Mr. Arun" smtext="Sr. Tech support" />
                            <Introimg img="./img/intro/Ravikiran.png" text="Mr. Ravikiran " smtext="Sr. Tech support" />
                            <Introimg img="./img/intro/Akshay.png" text="Mr. Akshay" smtext="Sr. Sales" />
                            <Introimg img="./img/intro/Karthik.png" text="Mr. Karthik" smtext="Project M., Singapore" />
                            <Introimg img="./img/intro/Murali.png" text="Mr. Murali " smtext="Country Manager." />
                            <Introimg img="./img/intro/Karthi.png" text="Mr. Karthi" smtext="Asst. Manager" />
                            <Introimg img="./img/intro/Dinesh (2).png" text="Mr. Dinesh" smtext="Sr. SAP Developer" />
                            <Introimg img="./img/intro/Rintu.png" text="Mr. Rintu " smtext="A.D, Manager" />
                            <Introimg img="./img/intro/Pavithran.png" text="Mr. Pavithran" smtext="Lead PMO" />
                            <Introimg img="./img/intro/Priyanka.png" text="Ms. 🧘‍Priyanka" smtext="Sr. Quality Engineer" />
                            <Introimg img="./img/intro/Sakthi (2).png" text="Mr. Sakthi " smtext="Sr. Tester, Chennai" />
                            <Introimg img="./img/intro/Ranjan.png" text="Mr. Ranjan" smtext="Reporting Manager" />
                            <Introimg img="./img/intro/Promodh.png" text="Mr. Promodh" smtext="Sr. HR, iOPEX Bang" />
                            <Introimg img="./img/intro/Anand.png" text="Mr. Anand" smtext="A.D, Design strategy" />
                            <Introimg img="./img/intro/Sam.png" text="Mr. Sam" smtext="UI UX Designer" />
                        </div>
                        <div className='col_2 col_1_lg videset-intro-split'>
                            <Introimg img="./img/intro/godwin.png" text="Mr. Godwin" smtext="Sr. Support Engineer" />
                            <Introimg img="./img/intro/hearts.png" text="Additional" smtext="Thank you!" />
                        </div>
                        
                    </div>
                    <div className="mtb-60">
                        <h4 className="inner-heading">Track to #2026</h4>
                        <p className="vibeset-cnt mt-24">
                        In 2019, I set a three-year goal 🎯, and by 2022, I successfully <span>achieved it</span>! Now, I've outlined my <span>next short-term goal for 2026</span>. Currently, I'm diligently working towards that vision, and this website is a part of that journey. I believe in myself, and after conquering this, I'll set my sights on the <span>next goal for #2030.</span>
                        </p>
                        <p className="vibeset-cnt mt-24">
                        Thanks for taking the time to read my blog! 📖 If you'd like to stay connected, just <span>"SAY Hi"</span> 👋, and we'll keep in touch.
                        </p>
                        <p className="vibeset-cnt mt-24">
                        <span>Signing off with love</span>❤️,<br/> <span>Rajasekar</span><br/> <span>UI UX Designer</span> 🚀 
                        </p>
                    </div>
                    <div className="ptb-100">
                        <div ref={DesignParent}>
                    <h3 class="section-heading"
                     data-aos="fade-up"
                     data-aos-delay="50"
                     data-aos-duration="1000"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="true">Special</h3>
                    <h4 class="banner-sub-text black-gradeiant">Thanks to developers</h4>
                    <p className="vibeset-cnt mt-24 ts-24">
                        Transforming the precise design into visuals is incredibly exciting for development; behind this developer experience, I encounter my superheroes
                    </p>
                    <div className={"col_2 col_1_lg videset-intro-split"}>
                            <a href='https://www.linkedin.com/in/pechimuthu-sivakumar/' target='_blank'><Introimg img="./img/intro/pechimuthu.png" text="Mr. Pechimuthu" smtext="Lead Full stack Dev." /></a>
                            <Introimg img="./img/intro/raaj-sekar.png" text="Mr. Rajasekar" smtext="Lead, UI UX Desigenr" />
                        </div>
                        </div>
                    </div>                       
                </Col>
            </Row>
        </VibeSet>



        {/* <MacBackground>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={ScaleParrallexUi.ref}>Ui & U Xperience</h3>
                    <h4 class="banner-sub-text">with case study</h4>
                </Col>
            </Row>
                <Row className="mac-img-div" justify={"center"}>
                    <img className="mac-img" src={Desktop} alt="Mac Image"/>
                    <img className="fade-down" src={Fadedown} alt="Mac Image"/>
                    <Col span={19} className="ux-section">
                    <Col span={10} xs={24} sm={24}>
                        <a href="#" className={"ux-aps"}>
                            <img src={Icont} alt="" />
                        </a>
                    </Col>
                    <Col span={10} xs={24} sm={24}>
                        <a href="#" className={"ux-aps"}>
                                <img src={Icont} alt="" />
                            </a>
                    </Col>
                    </Col>                   
                </Row>
        </MacBackground> */}
       </>
    );
}

export default Blog;

const Testimonial = styled.div`
    padding: 152px 0;
    background-color: var(--Portfolio-Black, #000);
    margin-top: -1px;
    position: relative;
    overflow: hidden;
    .testMonial{
        position: relative;
        width: 100%;
        padding-left: 20vw;
    }
    
    .mic-img{
        position: absolute;
        left:0px;
        opacity: 0.5;
    }
    .star-img{
        position: absolute;
        right:0px;
        opacity: 0.5;
    }
    .testimonial-wrapper{
        white-space: nowrap;
        padding-top: 80px;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        width:fit-content;
    }
    .testimonial-col{
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        padding-bottom: 65px;
        display: flex;
        margin-left: 30px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .testimonial-col:hover .testi-prof{
        width: 100%;
        height: 100%;
    }
    .testi-div{
        border-radius: 24px;
        background: radial-gradient(50% 175.4% at 50% -4950%, rgba(100, 210, 255, 0.05) 0%, rgba(0, 0, 0, 0.00) 12%),rgba(242, 242, 242, 0.16);
        box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.40);
        width: 358px;
        padding: 36px 18px 60px 18px;
        text-align: center;
        // overflow: hidden;
    }
    .testi-div .testi-img{
        margin: 6px auto 0 auto;
    }
    .testi-div .testi-cnt{
        color: var(--Portfolio-White, #FFF);
        text-align: center;
        /* Desktop/Poppins/Regular 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: normal;
    }
    .testi-owner{
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 88px;
        background: var(--Portfolio-Black, #1F1F1F);
        width: 195px;
        height: 72px;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-43%, -50%);
    }
    .testi-prof{
        width: 0%;
        height: 0%;
        transition: all 0.3s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9;
        transform: translate(-50%,-50%);
    }
    .testi-owner div{
        text-align: left;
    }
    .testi-owner div .testi-name{
        color: var(--Portfolio-White, #FFF);
        /* Desktop/Poppins/Medium 12 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .testi-owner div .testi-company{
        color: var(--Portfolio-Grey-200, #8F8F8F);
        /* Desktop/Poppins/Light 12 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    @media only screen and (max-width: 769px) {
        .testMonial{
            padding-left: 0;
        }
        .testimonial-wrapper{
            display: block;
        }
        .testimonial-col{
            margin-left: 0px;
        }
    }
`
const VibeSet = styled.div`
    padding: 80px 0;
    .black-gradeiant{
        background: radial-gradient(14.89% 14.89% at 50% 50%, #EFF1FF 0%, #000 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
Unexpected Turns

    }
    .star-section{
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: auto;
        position: relative;
    }
    .star-section p{
        background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        /* Desktop/Poppins/Regular 56 */
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .star-section span{
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        right: 85px;
        top: 51px;
    }
    @media only screen and (max-width: 769px) {
        .star-section{
            display: block;
            text-align: center;
        }
        .star-section span{
            display: none;
        }
    }
    .intro-Sec{
        display: flex;
        width: 262px;
        height: 104px;
        padding: 20px 0px 14px 116px;
        justify-content: left;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--Portfolio-gradient, #FA0006);
        background: url(${require('./img/intro/Rectangle-305.png')});
        overflow: hidden;
        background-repeat: no-repeat;
        position: relative;
        margin: 24px auto 0 auto;
        background-position: center center;
    }
    .intro-Sec .intro-img{
        position: absolute;
        left: -17px;
    }
    .intro-text{
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .intro-pos{
        color: var(--Portfolio-Black, #1F1F1F);
        /* Desktop/Poppins/Medium 12 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .inner-heading{
        color: var(--Portfolio-Apple-Black, #000);
        text-align: center;
        /* Desktop/Poppins/Regular 56 */
        font-family: Poppins;
        font-size: 4vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    @media only screen and (max-width: 768px) {
        .inner-heading{
            font-size: 32px;
        }
    }
    .vibeset-cnt{
        color: var(--Portfolio-Grey-200, #8F8F8F);
        text-align: center;

        /* Desktop/Poppins/Light 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .vibeset-cnt span{
        background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /* Desktop/Poppins/Light 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .mt-24{
        margin-top: 24px !important;
    }
    .mtb-60{
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .ptb-100{
        padding: 40px 0px 100px 0px;
    }
    .ts-24{
        color: var(--Portfolio-Grey-200, #8F8F8F);
        text-align: center;
        /* Desktop/Poppins/Light 24 */
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .heading-sub-cnt{
        color: var(--Portfolio-Grey-200, #8F8F8F);
        text-align: center;
        /* Desktop/Poppins/Light 24 */
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .heading-sub-cnt span{
        background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .videset-intro-split{
        justify-content: center;
        gap: 0px 24px;
        width: fit-content;
        margin: auto;
    }
`

const LifeofRaaj = styled.section`
    position:relative;
    display: grid;
    algin-items: center;
    justify-content: center;
    overflow: hidden;
    h4{
        color: var(--Portfolio-White-50, #FAFAFF);
        /* Desktop/Poppins/Regular 128 */
        font-family: Poppins;
        font-size: 7vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -10.24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .life-img{
        width: 100%;
        height: 100%;
    }
    .fade-imgs{
        width: 100%;
        position: absolute;
    }
    .fade-imgs.fade-up{
        bottom: 0;
    }
    .fade-imgs.fade-left{
        left: 0;
    }
    .fade-imgs.fade-right{
        right: 0;
    }
    .fade-imgs.fade-down{
        top: 0;
    }
    @media only screen and (max-width: 769px) {
        h4{
            letter-spacing: -4.24px;
        }
    }
`

// const MacBackground = styled.section`
//     padding: 152px 0;
//     background-color: var(--Portfolio-Black, #000);
//     margin-top: -1px;
//     postion: relative;

//     .mac-img-div{
//         position: relative;
//         margin-top: 41px;
//         overflow: hidden;
//         padding: 30px 0px;
//     }
//     .fade-down{
//         position: absolute;
//         bottom: 0;
//     }
//     .mac-img-div .mac-img{
//         position: absolute;
//         top: 0px;
//         overflow: hidden;
//         height: 100%;
//     }
//     .ux-section{
//         display: grid;
//         grid-template-columns: repeat(2,1fr);
//         width: 100%;
//     }
//     @media only screen and (max-width: 769px) {
//         .ux-section{
//             grid-template-columns: repeat(1,1fr);
//         }
//     }
// `

const StyledBanner = Styled.div`
    height: 100vh;
    background: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-sec-pdd{
        padding-left:10vw;
    }
`
const BannerText = Styled.h1`
    text-align: Left;
    font-family: 'Poppins', sans-serif;
    font-size: 13vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media only screen and (max-width: 767px) {
        font-size: 34px;
        line-height: 56px;
        letter-spacing: 0px;
    }
`;

const SmallText = Styled.h4`
    font-size: 56px;
    line-height: 84px;
    color: var(--Portfolio-White, #FFF);
    left: 0px;
    letter-spacing: normal;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 3000px;
    @media only screen and (max-width: 768px) {
        width: fit-content;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 26px;
    }
`;