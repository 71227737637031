import "./MasterCss.css";
import "./style.css"
import {Col,Row,Image,Button} from 'antd';
import { useParallax } from "react-scroll-parallax";
import Styled, { styled } from "styled-components";
import React,{useEffect,useState,useRef} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
    DoubleRightOutlined,
    ExportOutlined
  } from '@ant-design/icons';
  import AOS from 'aos';
import 'aos/dist/aos.css';
import {notification,message} from "antd";
import { json } from "react-router-dom";
const HorizontalScrollCarousel = () => {
    const parallax = useParallax({
        easing: [1, -0.40, 0, 1.34],
        translateX: [0, -40],
    });
  
    return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    <SmallText ref={parallax.ref}>
    Give a hello, and I'll respond with a cheerful hi!<span> 🤝 😊</span>
      </SmallText>
      </Col>
    );
  };
const Sayhi = () => {

    const HeadContainer = useRef();
    const Slideer = useRef();    
    AOS.init();

    useEffect(() => {
        if(window.innerWidth > 768){
            const t2 = gsap.to(Slideer.current, {
                x: -(Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                duration: .1,
                ease: "none",
            });
            ScrollTrigger.create({
                trigger: HeadContainer.current,
                start : "top top",
                end: () => (Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                pin: true,
                animation:t2,
                scrub:1,
                invalidateOnRefresh:true,
                // markers:true
    
            })            
            return () => {
                t2.revert();
            }
        }        
    },[]);

    const Container = useRef();
    const ref = useRef([]);
    ref.current = [];

    useEffect(() => {
        ref.current.forEach((el) => {
        const anim = gsap.fromTo(el,{duration: 5,autoAlpha: 0, y: 600,onStart: () => {el.style.opacity = 0;}}, {duration: 1, autoAlpha: 1, y: 0,opacity: 1,onComplete: () => {el.style.opacity = 1;}});
    ScrollTrigger.create({
      trigger: Container.current,
      start: "top center",
      end: "30% 50%",
      animation: anim,
      toggleActions: "play none none reverse",
    });
    })
    },[]);


     
  const scaleParallax = useParallax({
    easing: "easeOutQuad",
    scale: [1.5, 1],
  });

  const scaleParallax1 = useParallax({
    easing: "easeOutQuad",
    scale: [1.5, 1],
  });

    const addtoRefs = (el) => {
        if (el && !ref.current.includes(el)) {
            ref.current.push(el);
        }
    }

    const [messageApi, contextHolder] = notification.useNotification();
    // const [messageMaster, setmessageMaster] = message.useMessage();
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [company,setComapny] = useState("");
    const [msg,setMsg] = useState("");
    const [load,setloading] = useState(false);

    const HandleSubmit = (event) => {
        event.preventDefault();
        if(name == ""){
            openMessage("error","Please enter your name.");
            return;
        }
        if(load){
            messageApi.open({
                type: 'loading',
                content: 'Action in progress..',
                duration: 0,
              });
              // Dismiss manually and asynchronously
              setTimeout(messageApi.destroy, 2500);
            }
        if(load == false){
            setloading(true);
        fetch('https://raajux.com/mailer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                company: company,
                message: msg
            }),
        }).then((response) => {
            if(response.status === 200){
                openMessage("success","Thank you for contacting us. We will get back to you soon.");
            }else{
                openMessage("error","Something went wrong. Please try again.");
            }
        }).catch((error) => {
            openMessage("error","Something went wrong. Please try again.");
        });
    }
};    
    const openMessage = (option,text) => {
        const placement = 'bottom';
        if(option == "success"){
        messageApi.success({
            message: option,
            description: text,
            placement,
        })
        }else{
        messageApi.error({
            message: option,
            description: text,
            placement,
        })
        }
    };


    return (
        <>
        {contextHolder}
        {/* {setmessageMaster} */}
        <div>
       <StyledBanner ref={HeadContainer}>
            <Row justify="center" className="sub-page-banner-sec">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='banner-sec-pdd'>
                    <BannerText className="sub-page-banner">Let's handshake</BannerText>
                <SmallText ref={Slideer}>
                    Give a hello, and I'll respond with a cheerful hi! <span>🤝😊</span>
                </SmallText>
                </Col>                
            </Row>
       </StyledBanner>
       </div>

        <div style={{marginTop: "-1px"}}>
       <ContactFrom>
       <div  data-aos="fade-up"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    >
        <Row justify="center" style={{alignItems:'self-end',overflow:'hidden'}} >
            <Col span={6} xs={24} sm={24} md={6} lg={6} xl={6} className="pb-lg-160" ref={addtoRefs}>
                <img className="raajSayhi" src={require("./img/Raaj_sayhi_pic.png")} alt="Portfolio"/>
            </Col>
           
            <Col span={6} xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="conatcform">
                    <form onSubmit={HandleSubmit} method="POST">
                        <div className="form-group">
                        <input type="text" name="name" id="form-name" placeholder="" onChange={(e) => setName(e.target.value)} />
                        <label for="form-name">Your Name</label>
                        <div className="animeline"></div>
                        </div>
                        <div className="form-group">
                        <input type="text" name="email" id="Email-name" placeholder="" onChange={(e) => setEmail(e.target.value)} />
                        <label for="Email-name">Email</label>
                        <div className="animeline"></div>
                        </div>
                        <div className="form-group">
                        <input type="text" name="company" id="Company-name" placeholder="" onChange={(e) => setComapny(e.target.value)}/>
                        <label for="Company-name">Company</label>
                        <div className="animeline"></div>
                        </div>
                        <div className="form-group">
                        <textarea type="text" name="message" id="form-msg" rows="3" placeholder="" onChange={(e) => setMsg(e.target.value)}/>
                        <label for="form-msg">Message</label>
                        <div className="animeline"></div>
                        </div>
                        <div className="form-group">
                        <button type="submit" style={{Width:'156px'}}>
                            Send <DoubleRightOutlined />
                        </button>
                    </div>
                    </form>
                </div>
            </Col>
        </Row>
        </div>
            
       </ContactFrom>
       </div>
        <div>
        <SocialLinks>
        <div  data-aos="fade-up"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    >
            <Row justify="center">
                <h3 class="section-heading" ref={scaleParallax.ref}>Let's chat <span style={{color:'#fff'}}>📬</span> where you </h3>
                <h4 class="banner-sub-text">feel comfy! <span style={{color:'#fff'}}>🌟</span></h4>
            </Row>
            </div>
            <div className="social-container m_40_lg">
            <div className="wrapper">
                <div className="col_3 col_3_xxl col_3_xl col_3_lg col_2_md col_1_sm g_25 a_i_c" style={{overflow:"hidden"}}>
                    <div className="social-link"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    >
                        <div className="social-icon">
                            <img src={require("./img/Intagram_3d.png")} alt="Instagram"/>
                            <div className="social-card">
                                <p className="user-name">raaj.ux</p>
                                <p className="quoteline">
                                Ràáj the designer ✨
                                </p>
                                <a href="https://www.instagram.com/raaj.ux/" target="_blank" className="classicBtn">
                                    Follow <ExportOutlined />
                                </a>
                            </div>
                            <div className="tagline m_25_lg">
                            Semi casual mode
                            </div>
                        </div>
                    </div>
                    <div className="social-link"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    >
                        <div className="social-icon">
                            <img src={require("./img/Linkedin_3d.png")} alt="Instagram"/>
                            <div className="social-card">
                                <p className="user-name">Rajasekar A</p>
                                <p className="quoteline">
                                Product designer @concentix🏅
                                </p>
                                <a href="https://www.linkedin.com/in/rajasekar-a-product-designer/" target="_blank" className="classicBtn">
                                    Connect <ExportOutlined />
                                </a>
                            </div>
                            <div className="tagline m_25_lg">
                            Professional mode
                            </div>
                        </div>
                    </div>
                    <div className="social-link"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    >
                        <div className="social-icon">
                            <img src={require("./img/watsapp_3d.png")} alt="Instagram"/>
                            <div className="social-card">
                                <p className="user-name">Raaj</p>
                                <p className="quoteline">
                                Raajvj.jpg #spreadlove❤️
                                </p>
                                <a href="https://api.whatsapp.com/send?phone=919566846321&text=Hello Raaj, I obtained your contact information from your personal website. I'm interested in staying connected."  target="_blank" className="classicBtn">
                                    Say Hi <ExportOutlined />
                                </a>
                            </div>
                            <div className="tagline m_25_lg">
                            Friendly mode
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </SocialLinks>
        </div>

        <StyledNandri>
            <div className="wrapper">
                <p>crafted with love and passion. ❤️✨</p>
                <h3 class="section-heading" ref={scaleParallax1.ref}>"நன்றி "</h3>
            </div>
        </StyledNandri>


       </>
    )
}

export default Sayhi;

const StyledNandri = Styled.section`
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    justify-content: center;
    align-items: center;
    background: url(${require('./img/Thankyou_all_language.png')});
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: 100% 100%;
    .wrapper{
        overflow:hidden;
    }
    p{
        color: var(--Portfolio-Apple-Black, #000);
        text-align: center;
        /* Desktop/Poppins/Light 24 */
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    h3{
        text-align: center;
/* Desktop/Poppins/Regular 56 */
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow:hidden;
    }
`

const ContactFrom = Styled.section`
    padding: 156px 0;
    background: var(--Portfolio-Apple-Black, #000);
    margin-top: -1px;
    display:none;
    .pb-lg-160{
        padding-bottom: 160px;
    }
    @media only screen and (max-width: 769px) {
        .pb-lg-160{
            padding-bottom: 20px;
        }
    }
    .conatcform form{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        justify-content: left;
        justify-items: flex-start;
        gap: 24px
    }   
    .conatcform form{ 
        .form-group{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            justify-content: left;
            justify-items: flex-start;
            width: 85%;
            position: relative;
            overflow: hidden;
        }
        input{
            height: 56px;
        }
        textarea{
            min-height: 132px;
        }
        input,textarea{
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #8F8F8F;
            padding: 8px 15px;            
            color: var(--Portfolio-White, #FFF);
            font-size: 20px;
            font-family: Poppins;
        }
        label{
            font-family: Poppins;
            font-weight: 400;
            font-size: 32px;
            color: #8F8F8F;
            line-height: 48px;
            position: absolute;
            // transition: all 0.3s ease;
        }
        ::placeholder{
            color: var(--Portfolio-White, #FFF);
        }
        ::-ms-input-placeholder{
            color: var(--Portfolio-White, #FFF);
        }
        button{
            color: var(--Portfolio-White, #FFF);
            /* Desktop/Poppins/Light 20 */
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            padding: 8px 24px;
            border-radius: 96px;
            border: 1px solid var(--Portfolio-gradient, #FA0006);
            background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
            margin-top: 40px;
            width: 156px;
        }
        .animeline{
            width: 0%;
            height: 1px;
            background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
            position: absolute;
            left: 0px;
            bottom: 0px;
            transition: all 0.3s ease;
            display: block;
        }
        
        input,textarea:focus, input,textarea:focus-visible{
            outline: none;
        }
    }
`

const SocialLinks = Styled.section`
        padding: 156px 0;
        background: var(--Portfolio-Apple-Black, #000);
        .section-heading {
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 5vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -3.24px;
            background: var(--Portfolio-gradient);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: 100%;
            text-align: center;
            span{
                -webkit-background-clip: text;
                -webkit-text-fill-color: #fff;
            }
            * {
                overflow: initial;
            }
        }
        .banner-sub-text{
            span{
                -webkit-background-clip: text;
                -webkit-text-fill-color: #fff;
            }
        }
        .col_3_xxl{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            justify-content: center;
            justify-items: center;
            align-items: center;
            gap: 24px;
        }
        @media only screen and (max-width: 769px) {
            .col_3_xxl{
                grid-template-columns: repeat(1,1fr);
            }
        }
        .social-link{
            position: relative;
            width: 100%;
        }
        .social-icon{
            position: relative;
            width: 100%;
            img{
                margin: auto;
                margin-bottom: -70px;
            }
        }
        .social-card{
            border-radius: 16px;
            background: linear-gradient(0deg, rgba(242, 242, 242, 0.16) 0%, rgba(242, 242, 242, 0.16) 100%), radial-gradient(50% 175.4% at 50% -4950%, rgba(100, 210, 255, 0.05) 0%, rgba(0, 0, 0, 0.00) 12%);
            box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.40);
            padding: 56px 18px;
            text-align: center;
            width: 100%;
           
        }
        .user-name{
            color: var(--Portfolio-White, #FFF);
            text-align: center;
            /* Desktop/Poppins/Regular 32 */
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .quoteline{
            color: var(--Portfolio-Grey-200, #8F8F8F);
            text-align: center;
            /* Desktop/Poppins/Regular 16 */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 8px;
            margin-bottom: 24px;
        }
        .classicBtn{
            display: flex;
            width: 203px;
            padding: 8px 24px;
            gap: 10px;
            justify-content: center;
            align-items: center;
            border-radius: 96px;
            border: 1px solid var(--Portfolio-White-50, #FAFAFF);
            color: var(--Portfolio-White, #FFF);
            /* Desktop/Poppins/Light 16 */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: center;
            margin: auto;
            text-decoration: none;
        }
        .classicBtn:hover{
            background: var(--Portfolio-gradient) !important;
            color: #fff !important;
            border: 1px solid transparent !important;
            background-repeat: no-repeat !important;
        }
        .tagline {
            color: var(--Portfolio-White, #FFF);
            text-align: center;
            /* Desktop/Poppins/Regular 16 */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
        }
`



const StyledBanner = Styled.div`
    height: 100vh;
    background: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-sec-pdd{
        padding-left:10vw;
    }
`
const BannerText = Styled.h1`
    text-align: Left;
    font-family: 'Poppins', sans-serif;
    font-size: 10vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media only screen and (max-width: 767px) {
        font-size: 34px;
        line-height: 56px;
        letter-spacing: 0px;
    }
`;

const SmallText = Styled.h4`
    font-size: 56px;
    line-height: 84px;
    color: var(--Portfolio-White, #FFF);
    left: 0px;
    letter-spacing: normal;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 3000px;
    @media only screen and (max-width: 768px) {
        width: fit-content;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 26px;
    }`;