import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
const Newlayout = () => {
  return (
    <>
        <Header />
            <Outlet />
            {window.location.pathname === '/say-hi' ? null : <Footer />}
    </>
    
  )
}

export default Newlayout