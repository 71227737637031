import {Col,Row,Tabs} from 'antd';
import { useParallax } from "react-scroll-parallax";
import Styled, { styled } from "styled-components";
import ParalexBg from "./img/Portfolio_coverBG.png";
import Desktop from "./img/Desktop.png";
import Icont from "./img/iconnect.png";
import FadeUp from './img/div.project-fade.png';
import Fadedown from './img/div.project-fade -down.png';
import Desktop2 from './img/bg/Desktop (1).png';
import React,{ useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Tilt from "react-parallax-tilt";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ExportOutlined} from '@ant-design/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
  }
const Portfolio = () => {
    AOS.init();
    const HeadContainer = useRef();
    const Slideer = useRef();  
    

    useEffect(() => {
            if(window.innerWidth > 768){
                const t2 = gsap.to(Slideer.current, {
                    x: -(Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                    duration: .1,
                    ease: "none",
                });
                ScrollTrigger.create({
                    trigger: HeadContainer.current,
                    start : "top top",
                    end: () => (Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                    pin: true,
                    animation:t2,
                    scrub:1,
                    invalidateOnRefresh:true,
                    // markers:true
        
                })
                return () => {
                    t2.revert();
                }
            }
    },[]);

    const ExclusiveRef = useRef(null);
    const ExclusiveSlide = useRef(null);
    const ExploreRef = useRef(null);
    const ExplorefSlider = useRef(null);

    useEffect(() => {
        if(window.innerWidth > 768){
            const t3 = gsap.to(ExclusiveSlide.current, {
                x: -(ExclusiveSlide.current.offsetWidth - ExclusiveRef.current.offsetWidth) * 1.5 + "px",
                duration: .1,
                ease: "none",
            });
            ScrollTrigger.create({
                trigger: ExclusiveRef.current,
                start : "top -20%",
                end: () => (ExclusiveSlide.current.offsetWidth - ExclusiveRef.current.offsetWidth) * 1 + "px",
                pin: ExclusiveRef.current,
                animation:t3,
                scrub:1,
                invalidateOnRefresh:true,
                id:"exclusive",
                // horizontal: true,
                // pinSpacing: false,
                // markers:true
            })      
            return () => {
                t3.revert();
            }
        }
    },[ExclusiveSlide.current]);

    useEffect(() => {
        if(window.innerWidth > 768){
        const t4 = gsap.to(ExplorefSlider.current, {
            x: -(ExplorefSlider.current.offsetWidth - ExploreRef.current.offsetWidth) * 1.5 + "px",
            duration: .1,
            ease: "none",
        });
        ScrollTrigger.create({
            trigger: ExploreRef.current,
            start : "top -5%",
            end: () => (ExplorefSlider.current.offsetWidth - ExploreRef.current.offsetWidth) * 1 + "px",
            pin: true,
            animation:t4,
            scrub:1,
            invalidateOnRefresh:true,
            id:"explore",
            // markers:true

        })  
        return () => {
            t4.revert();
        }
    }
    },[ExplorefSlider.current])

    const ScaleParrallexUi = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    })
    const ScaleParrallexEx = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    });
    const ExplorePra = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    });
    const VishualPra = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    });

    const GraphicPra = useParallax({
        easing: "easeOutQuad",
        scale: [1.5, 1],
    });

    const Front = useRef(null);
    const Back = useRef(null);

    const sFront = 150
    const sBack = 400

    const ParallexHoverEff = (e) => {
        const x = e.clientX;
        const y = e.clientY;

        Front.current.style.transform = `translate(
            ${x / sFront}%,
            ${y / sFront}%
        )`;

        Back.current.style.transform = `translate(
            ${x / sBack}%,
            ${y / sBack}%
        )`
    }

    const sliderRef = useRef(null);
    const[current, setCurrent] = useState(0);

    const onPreviousClick = () => {
        sliderRef.current.slickPrev();
       };
       
       const onNextClick = () => {
        sliderRef.current.slickNext();
       };

       const beforechange = () => { 

       };

    const Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        Draggable: false,
        nextArrow: <div className='customized-arrow next' onNextClick={onNextClick}><span>Qualification</span></div>,
        prevArrow: <div className='customized-arrow prew' onPreviousClick={onPreviousClick}><span>Certification</span></div>,
        beforeChange: (current, next) => {
            setCurrent(next);
        }
    }

    const SpotLightSet = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        Draggable: false,
        autoplay: true,
        speed: 500,
        cssEase: 'linear'
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAnimation, setModalAnimation] = useState("slide-up");
    const [modalImg,setmodalImg] = useState("");

        function close() {
            setModalAnimation("slide-down");
            setTimeout(() => {
                setIsModalOpen(false);
                // Sets it for the next time so it slides up when called
                setModalAnimation("slide-up");
            }, 600);
        }
    
        function ModalOpen(popimg){
            setmodalImg(popimg);
            setIsModalOpen(true);
        }
    // window.addEventListener('mousemove', ParallexBG)

    return (
        <>
    {isModalOpen && (
                    <div className={`modal-overlay`} onClick={() => close()}>
                        <div className='modal-container'>
                        <div className={`modal-content ${modalAnimation}`}>
                        {/* <img src={require('./img/Close_fill_popup.png')} onClick={() => close()} className='close-icon'/>
                        <img src={require('./img/Traffic_Lights.png')} className='traficlights' /> */}
                            <div className="feedbackModalHeader">
                                <img
                                    src={require(`./img/${modalImg}`)}
                                    alt=""
                                    className="dislikeBtnNonFilled"
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                )}
        <div>
       <StyledBanner ref={HeadContainer}>
            <Row justify="center" className="sub-page-banner-sec">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='banner-sec-pdd'>
                    <BannerText className="sub-page-banner">Crafting</BannerText>
                <SmallText ref={Slideer}>
                    Seamless Experiences: A Glimpse into my UX Design Journey. <span>🚀✨</span>
                </SmallText>
                </Col>                
            </Row>
       </StyledBanner>
       </div>

       <ParrallaxBanner className="parrallax-banner-fir" onMouseMove={ParallexHoverEff}>      
        <img src={ParalexBg} alt="Portfolio" className='bg-parralex front-layer' ref={Front}/>
        <img src={Fadedown} className="fade-up" alt="Portfolio" />
        <img src={FadeUp} className="fade-down" alt="Portfolio" />
        <Tilt style={{overflow:"hidden"}}>
            <Row justify="center" className="parrallax-banner-sec" style={{overflow:"hidden"}}>
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19} className='back-layer' ref={Back} >
            <div className="parallax-hover-sec">
                
                <div className={"hover-scroller"}>
                    <p>Achieved</p>
                    <h4>100%</h4>
                    <p>satisfaction deliverables</p>
                    <img src={require('./img/hover-4.png')} alt="Portfolio" />
                </div>
                
                <div className={"hover-scroller"}>
                    <p>Over</p>
                    <h4>10,000+</h4>
                    <p>Screens Designed</p>
                    <img src={require('./img/hover-2.png')} alt="Portfolio" />
                </div>
            </div>
            <div className="parallax-hover-sec" style={{justifyContent: 'space-between',margin: '25px 0px'}}>
            <div className={"hover-scroller"}>
                    <p>Solved</p>
                    <h4>100+</h4>
                    <p>UX Challenges</p>                   
                    <img src={require('./img/UXchallengehover.jpg')} alt="Portfolio" />
                </div>
                <div className={"hover-scroller center-animation"}>
                    <p>Here are some<br/> additional<br/> insights</p>
                </div>
                <div className={"hover-scroller"}>
                    <p>Received</p>
                    <h4>100+</h4>
                    <p>Appreciations</p>
                    <img src={require('./img/hover-3.png')} alt="Portfolio" />
                </div>
                </div>
            <div className="parallax-hover-sec">
                <div className={"hover-scroller"}>
                    <p>Mentored</p>
                    <h4>1000+</h4>
                    <p>College students</p>
                    <img src={require('./img/hover-5.png')} alt="Portfolio" />
                </div>
                
                <div className={"hover-scroller"}>
                    <p>Conducted</p>
                    <h4>10+</h4>
                    <p>workshops / Presentations</p>
                    <img src={require('./img/hover-6.png')} alt="Portfolio" />
                </div>
            </div>
            </Col>
            
            </Row>
            </Tilt>
       </ParrallaxBanner>

        <MacBackground style={{paddingBottom:"0px"}}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={ScaleParrallexUi.ref}>Ui & U Xperience</h3>
                    <h4 class="banner-sub-text">with case study</h4>
                </Col>
            </Row>
                <Row className="mac-img-div" justify={"center"}>
                    <img className="mac-img" src={Desktop} alt="Mac Image"/>
                    <img className="fade-down" src={FadeUp} alt="Mac Image"/>
                    <Col span={19} className="ux-section">
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('iConnect.jpg')} className={"ux-aps"}>
                            <span className='header-text'>iConnect</span>
                            <img src={Icont} alt="" style={{width: '100%'}}/>
                            <span className='view-txt'>View</span>
                        </a>
                    </Col>
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('Aranxt_image.jpg')} className={"ux-aps"}>
                        <span className='header-text'>ARAnxt</span>
                            <img src={require('./img/icon-3.png')} alt="" style={{width: '100%'}}/>
                            <span className='view-txt'>View</span>
                        </a>
                    </Col>
                    </Col>                   
                </Row>
        </MacBackground>

        <div style={{background:"#000"}}>
        <Exclusive className='Exclusive-sec' ref={ExclusiveRef}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={ScaleParrallexEx.ref}>Exclusive</h3>
                    <h4 class="banner-sub-text">Peek into Concealed Files</h4>
                </Col>
            </Row>
                <div className="exclusive-bg">
                    <img className="mac-img" src={Desktop2} alt="Mac Image"/>
                    <div className="exclusive-scroller-parent">
                    <div className="exclusive-scroll" ref={ExclusiveSlide}>
                            <div className='usx-grid'>
                            <a onClick={() => ModalOpen('Private files.jpeg')} className='ux-aps'>
                                <span className='header-text'>Birla opus</span>
                                <img className='exclusive-hover' src={require('./img/5_column_Portfolio_Hover.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt' style={{alignItems: 'start'}}>View</span>
                                </a>
                                </div>
                                
                            <div className='usx-grid'>
                            <a onClick={() => ModalOpen('1builders.jpg')} className='ux-aps'>
                                <span className='header-text'>1Builders</span>
                                <img className='exclusive-hover' src={require('./img/5_column_Portfolio_Hover-1.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt' style={{alignItems: 'start'}}>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a onClick={() => ModalOpen('bebe.jpg')} className='ux-aps'>
                                <span className='header-text'>bebe</span>
                                <img className='exclusive-hover' src={require('./img/5_column_Portfolio_Hover-2.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt' style={{alignItems: 'start'}}>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a onClick={() => ModalOpen('Pettiq.jpg')} className='ux-aps'>
                                <span className='header-text'>Pettiq</span>
                                <img className='exclusive-hover' src={require('./img/5_column_Portfolio_Hover-3.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt' style={{alignItems: 'start'}}>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a onClick={() => ModalOpen('Zoho_Cash.jpg')} className='ux-aps'>
                                <span className='header-text'>Zoho Cash</span>
                                <img className='exclusive-hover' src={require('./img/5_column_Portfolio_Hover-4.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt' style={{alignItems: 'start'}}>View</span>
                            </a>
                            </div>
                    </div>
                    </div>
                </div>
            <img className="fade-down" src={FadeUp} alt="Mac Image"/>
        </Exclusive>   
        </div>

        <div style={{marginTop:"-1px",background:"#000"}}>
            <Explore ref={ExploreRef} style={{maxHeight:"fit-content !important",height:"fit-content !important"}}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={ExplorePra.ref}>Explore</h3>
                    <h4 class="banner-sub-text">The Live Apps/Web</h4>
                </Col>
            </Row>
            <div className='exlpore-sec'>
                <img className="mac-img" src={require('./img/bg/Explore_Live sites_BG.png')} alt="Mac Image"/>
                <div className='exlore-parent' >
                    <div className='Explore-Slider' ref={ExplorefSlider}>                   
                    <div className='explore-child'>
                            <div className='usx-grid'>
                            <a href='https://www.birlaopus.com/' target='_blank' className='ux-aps'>
                                <span className='header-text'>Birla opus</span>
                                <img className='exclusive-hover' src={require('./img/birla_opus.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://play.google.com/store/apps/details?id=com.growatiopex.iconnect&pcampaignid=web_share' target='_blank' className='ux-aps'>
                                <span className='header-text'>iConnect</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-1.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://play.google.com/store/apps/details?id=com.beeyondzero.admin.dogsnshows&pcampaignid=web_share' target='_blank' className='ux-aps'>
                                <span className='header-text'>Dogsnshows</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-2.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://iqualix.com/' target='_blank' className='ux-aps'>
                                <span className='header-text'>iQualix</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-3.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://play.google.com/store/apps/details?id=com.JKC.WhiteBoard&pcampaignid=web_share' target='_blank' className='ux-aps'>
                                <span className='header-text'>JKC</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-4.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                    </div>
                    <div className='explore-child'>
                    <div className='usx-grid'>
                            <a href='https://bournhall-clinic.ae/' target='_blank' className='ux-aps'>
                                <span className='header-text'>BournHall</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-5.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://play.google.com/store/apps/details?id=com.beeyondzero.admin.gomeat&pcampaignid=web_share' target='_blank' className='ux-aps'>
                                <span className='header-text'>GoMeat</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-6.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://play.google.com/store/apps/details?id=com.beeyondzero.hycan&pcampaignid=web_share' target='_blank' className='ux-aps'>
                                <span className='header-text'>Hycan</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-7.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                            <div className='usx-grid'>
                            <a href='https://www.9apps.com/android-games/com-mr-chesspool/version-history-1/' target='_blank' className='ux-aps'>
                                <span className='header-text'>Chesspool</span>
                                <img className='exclusive-hover' src={require('./img/Multi_column_Portfolio_Hover-8.png')} alt='Exclusive' style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            <img className="fade-down" src={FadeUp} alt="Mac Image"/>
            </Explore>
        </div>

        <MacBackground style={{paddingTop: "0px"}}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={VishualPra.ref}>Visual Stories</h3>
                    <h4 class="banner-sub-text">A Design Odyssey</h4>
                </Col>
            </Row>
                <Row className="mac-img-div" justify={"center"}>
                    <img className="mac-img" src={require('./img/bg/Visual Stories_BG.png')} alt="Mac Image"/>
                    <img className="fade-down" src={FadeUp} alt="Mac Image"/>
                    <Col span={19} className="ux-section">
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('Visual_Combo1.jpg')} className={"ux-aps"}>
                         <span className='header-text'>Visual Combo 1</span>
                            <img src={require('./img/2_column_Portfolio_Hover.png')} alt="" style={{width: '100%'}} />
                            <span className='view-txt'>View</span>
                        </a>
                    </Col>
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('Visual_combo2.jpg')} className={"ux-aps"}>
                            <span className='header-text'>Visual Combo 2</span>
                                <img src={require('./img/2_column_Portfolio_Hover -1.png')} alt="" style={{width: '100%'}} />
                                <span className='view-txt'>View</span>
                            </a>
                    </Col>
                    </Col>                   
                </Row>
        </MacBackground>

        <MacBackground style={{paddingTop: "0px"}}>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading" ref={GraphicPra.ref}>Graphic Alchemy</h3>
                    <h4 class="banner-sub-text">Designs in Motion</h4>
                </Col>
            </Row>
                <Row className="mac-img-div" justify={"center"}>
                    <img className="mac-img" src={require('./img/bg/Desktop.png')} alt="Mac Image"/>
                    <img className="fade-down" src={FadeUp} alt="Mac Image"/>
                    <Col span={19} className="ux-section">
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('Catshows Magazine.jpg')} className={"ux-aps"}>
                        <span className='header-text'>Catshows Magazine</span>
                            <img src={require('./img/cat_show.png')} alt="" style={{width: '100%'}}/>
                            <span className='view-txt'>View</span>
                        </a>
                    </Col>
                    <Col span={10} xs={24} sm={24} className='usx-grid'>
                        <a onClick={() => ModalOpen('Gomeat_Digital_Banner.jpg')} className={"ux-aps"}>
                        <span className='header-text'>Gomeat Digital Banner</span>
                                <img src={require('./img/meat_go.png')} alt="" style={{width: '100%'}}/>
                                <span className='view-txt'>View</span>
                            </a>
                    </Col>
                    </Col>                   
                </Row>
        </MacBackground>

        <Elevention>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19} style={{marginBottom:"32px"}}>
                    <h3 class="section-heading">Elevating Skills</h3>
                    <h4 class="banner-sub-text black-gradeiant">A Showcase of </h4>
                </Col>
            </Row>
            <div className='tab-carosels'>
                {current == 0 ? <img className="mac-img" src={require('./img/bg/tab1.png')} alt="Mac Image"/> : <img className="mac-img" src={require('./img/bg/tab2.png')} alt="Mac Image"/>}
                <Slider {...Settings} ref={sliderRef}>
                <div className='tab-panels' justify={"center"}>
                    <div className='profile-bg'>
                    <div className='profile-links'>
                        <img src={require('./img/udemy-wordmark.png')} alt="Udemy" style={{margin:"0px auto 24px auto"}}/>
                        <div className='col-lg-4'>
                        <div className='profile-child'>
                            <p>
                            Communication fundamentals
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-6cfe66f7-5874-4eaa-8d2e-9e4989ecbd92/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Critical thinking with emotional intelligence
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-df6a0efe-b9a8-4066-8fce-9c951d4e3f48/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Storytelling to influence
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-cf853e87-6fdf-4ee1-be0a-b1f382910987/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Time & task management techniques
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-1b1e0364-c30e-4d20-8125-acfaeb38a830/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Practical leadership skills
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-9124cd64-c699-4f9c-bb58-47ad51f3713f/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Scrum master & agile scrum certification
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-412aed3d-e8c0-4f66-bf32-5455c75f287c/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Create chatbot : build rapport & increase profit
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-979cd098-5a29-4021-b89e-da380bc441d8/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            The introduction of UX writing
                            </p>
                            <a href="https://udemy-certificate.s3.amazonaws.com/pdf/UC-2b82a25b-f8de-4ecb-85da-eaab2f58bacb.pdf" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Master class - Creative problem solving & decision making
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-c8cf02f5-84e2-4218-84bd-ba74b1635f27/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Master Digital product design : UX research & ui design
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-4f08ce5b-dee2-48e1-a8a3-c17e11413e2c/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Advanced product management : Vision, strategy & metrics
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-e37d5544-9c0c-4d41-af11-eee1d9c46479/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Presentation skills Master confidence presentations
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-f7ca72a1-4768-4a3b-8922-8b371c2f4432/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

                            <div className='profile-child'></div>
<div className='profile-child'>
                            <p>
                            Build persuasive products
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-ab23187d-04f5-437c-a853-979a50e29216/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>

<div className='profile-child'>
                            <p>
                            Practical sales techniques
                            </p>
                            <a href="https://www.udemy.com/certificate/UC-fcac8fff-714b-4ee2-9051-106e15431b1f/" className="classicBtn" target="_blank">
                                    Show credential <ExportOutlined />
                                </a>
                            </div>
                            <div className='profile-child'></div>
                        </div>
                        <hr style={{margin:"24px 0px"}} />
                        <div className='extra-links'>
                            <img src={require('./img/appolo.png')} style={{margin:'auto'}} />
                            <div className='below-app'>
                                <p className='main-txt'>
                                Animation & desktop publishing
                                </p>
                                <p className='addd-txt'>
                                Apollo computer education ltd, coimbatore
                                </p>
                                <p className='high-txt'>
                                “wrapped this up offline in June 2015”
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='tab-panels' justify={"center"}>
                    <div className='pursue-bg'>
                        
                        <div className='pursue-links'>
                            <div className='pursue-contain'>
                            <div className='purse-work' style={{backgroundColor:"#fff"}}>
                                <h4>Employment</h4>
                                <ul className='work-list'>
                                    <li>
                                            <p>
                                            Sr. UI UX Designer <span className='purse-desig'>Product Designer</span>
                                            <br />
                                            <span className='purse-company'>@Concentrix - Full time</span><span className='dul-txt'>Nov 2022 - present</span>
                                            <br/>
                                            <span className='purse-city'>Bangalore, KA, India.</span>
                                            </p>
                                    </li>

                                    <li>
                                            <p>
                                            Sr. UI UX Designer <span className='purse-desig'>Sr. Software Engineer</span>
                                            <br />
                                            <span className='purse-company'>@iOPEX Technologies</span><span className='dul-txt'>Feb 2022 - Nov 2022</span>
                                            <br/>
                                            <span className='purse-city'>Bangalore, KA. and Chennai, TN. India.</span>
                                            </p>
                                    </li>

                                    <li>
                                            <p>
                                            Lead UI UX Designer <span className='purse-desig'>Product Designer</span>
                                            <br />
                                            <span className='purse-company'>@Exalca Technologies</span><span className='dul-txt'>Oct 2020 - Jan 2022</span>
                                            <br/>
                                            <span className='purse-city'>Coimbatore, TN, India.</span>
                                            </p>
                                    </li>

                                    <li>
                                            <p>
                                            Sr. UI UX Designer <span className='purse-desig'>Graphic Designer</span>
                                            <br />
                                            <span className='purse-company'>@Beeyondzero s/w sol.</span><span className='dul-txt'>Jan 2019 - Sep 2020</span>
                                            <br/>
                                            <span className='purse-city'>Coimbatore, TN, India.</span>
                                            </p>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            <div className='pursue-contain'>
                            <div className='purse-clg' style={{backgroundColor:"#fff"}}>
                                <img src={require('./img/edu_Titlebar.png')} className='tittle-bar' />
                                <h4>Highest<br/> Qualification</h4>
                                <img src={require('./img/elevation/Skasc_logo.png')} alt="Qualification" style={{margin:'auto'}}/>
                                <p>
                                    UG <span className='purse-desig'>B.sc Software systems</span>
                                    <br />
                                    <span className='purse-company'>@Sri krishna arts & science</span><span className='dul-txt'>2015 - 2018 Batch</span>
                                    <br/>
                                    <span className='purse-city'>Coimbatore, TN, India.</span>
                                </p>
                            </div>
                            </div>
                         </div>
                    </div>
                </div>
                </Slider>
            </div>
                
        </Elevention>
        
        <SpotLight>
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19} style={{overflow: 'hidden'}}>
                    <h3 class="section-heading"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true"
                        style={{overflow: 'hidden'}}
                    >Spotlight</h3>
                    <h4 class="banner-sub-text black-gradeiant">My finished projects</h4>
                </Col>
            </Row>
            <SpotLight className='marquee'>
                <div class="marquee">
                <div class="marquee--inner">
                    <span>
                    <div><img src={require('./img/Spotlight.png')} alt="Mac Image"/></div>
                    </span>
                    <span>
                    <div><img src={require('./img/Spotlight.png')} alt="Mac Image"/></div>
                    </span>
                </div>
                </div>
            </SpotLight>
            <div className='compines' style={{marginBottom:'72px'}}>
                <img src={require('./img/companies.png')} alt="Mac Image" style={{margin:'auto'}}/>
            </div>
        </SpotLight>

       </>
    )
}

export default Portfolio;

const Elevention = styled.section`
    padding: 152px 0;
    margin-top: -1px;
    position: relative;
    padding-bottom: 24px;
    .slick-arrow{
        position: absolute;
        top: 35px;
        cursor: pointer;
        z-index: 999;
        width: 150px;
        height: 56px;
        padding: 8px 12px;
        text-align: center;  
        color: var(--Portfolio-Apple-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
        background: rgba(255, 255, 255, 0.88);
        overflow: hidden;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .slick-arrow:hover span{
        background: var(--Portfolio-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .slick-arrow.slick-disabled::before{
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
    .slick-next::before{
        content: 'Qualification';
        width: 131px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 80px;
        background: var(--Portfolio-Apple-Black, #000);
        transform: translate(-50%, -50%);
        opacity: 1;
        display: none;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
    .slick-prev::before{
        content: 'Certification';
        width: 131px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 80px;
        background: var(--Portfolio-Apple-Black, #000);
        transform: translate(-50%, -50%);
        opacity: 1;
        display: none;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
    .slick-next{
        left: 50%;
        transform: translate(0%, 0px);
        border: 1px solid #333;
        border-radius: 0px 80px 80px 0px;
        border-left: 0px;
    }
    .slick-prev{
        left: 50%;
        transform: translate(-99.7%, 0px);
        border-radius: 80px 0px 0px 80px ;
        border: 1px solid #333;
        border-right: 0px;
    }
    .pursue-contain{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    @media (max-width: 991px) {
        .pursue-contain{
            width: 100%;
            align-items: flex-start;
        }
    }
    .pursue-links{
        display: flex;
        gap: 24px;
        align-items: center;
        align-content: center;
        jsutify-content: center;
        justify-items: center;
        max-width: 930px;
        height: 479px;
        margin: 96px auto;
        h4{
            text-align: center;
            /* Desktop/Poppins/Regular 32 */
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 24px;
        }
        .purse-work{
            padding: 30px 30px 30px 0px;
            border-radius: 10.489px;
            border: 3.496px solid var(--Portfolio-Black, #1F1F1F);
            box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.40);
            width: 405px;
            background: url(${require('./img/Titlebar.png')}) no-repeat;
            max-width: 405px;
            .work-list {
                li{
                    position: relative;
                    padding-left: 90px;
                    padding-bottom: 12px;
                    padding-top: 12px;
                    p{
                        color: var(--Portfolio-Black, #1F1F1F);
                        /* Desktop/Poppins/Medium 12 */
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        .purse-desig{
                            padding-left: 12px;
                            margin-left: 12px;
                            border-left: 1px solid var(--Portfolio-Blue, #ECECEC);
                            color: var(--Portfolio-Black, #1F1F1F);
                            /* Desktop/Poppins/Light 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                        .purse-company{
                                font-family: Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
                                background-clip: text;
                                -webkit-background-clip: text;  
                                -webkit-text-fill-color: transparent;
                        }
                        .purse-city{
                            color: var(--Portfolio-Grey-200, #8F8F8F);
                            /* Desktop/Poppins/Regular 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                        .dul-txt{
                            color: var(--Portfolio-Grey-200, #8F8F8F);
                            /* Desktop/Poppins/Regular 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-left: 8px;
                        }
                    }
                    
                }
                li::before{
                    content: '';
                    background: url(${require('./img/elevation/Concentrix_logo.png')}) no-repeat;
                    position: absolute;
                    top: 0px;
                    left: 30px;
                    width: 32px;
                    height: 32px;
                }
                li:nth-child(2)::before{
                    background: url(${require('./img/elevation/Iopex_logo.png')}) no-repeat;
                }
                li:nth-child(3)::before{
                    background: url(${require('./img/elevation/Exalca_logo.png')}) no-repeat;
                }
                li:nth-child(4)::before{
                    background: url(${require('./img/elevation/Beeyondzero_logo.png')}) no-repeat;
                }
                li::after{
                    content: '';
                    background: url(${require('./img/Line_after.png')}) no-repeat;
                    position: absolute;
                    width: 10px;
                    height: 52%;
                    left: 41px;
                    top: 35px;
                }
                li:last-child::after{
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .pursue-links{
            width: 100%;
            display: block;
            .purse-clg{
                width: 100%;
                align-items: start;
                margin-top: 30px;
            }
        }
    }
    .purse-clg{
        border-radius: 6.852px;
        border: 2.284px solid var(--Portfolio-Black, #1F1F1F);
        box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.40);
        max-width: 405px;
        position: relative;
        padding: 30px;
        .tittle-bar{
            width: 100%;
            height: 20px;
            position: absolute;
            top: -2px;
            left: 0px;
        }
        p{
            color: var(--Portfolio-Black, #1F1F1F);
                        /* Desktop/Poppins/Medium 12 */
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-align: center;
                        margin-top: 24px;
                        .purse-desig{
                            padding-left: 12px;
                            margin-left: 12px;
                            border-left: 1px solid var(--Portfolio-Blue, #ECECEC);
                            color: var(--Portfolio-Black, #1F1F1F);
                            /* Desktop/Poppins/Light 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                        .purse-company{
                                font-family: Poppins;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
                                background-clip: text;
                                -webkit-background-clip: text;  
                                -webkit-text-fill-color: transparent;
                        }
                        .purse-city{
                            color: var(--Portfolio-Grey-200, #8F8F8F);
                            /* Desktop/Poppins/Regular 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                        .dul-txt{
                            color: var(--Portfolio-Grey-200, #8F8F8F);
                            /* Desktop/Poppins/Regular 12 */
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-left: 8px;
                        }
        }
    }
    .profile-child{
        display: grid;
        align-items: center;
        justify-content: center;
        justify-items: center;
        width: fit-content;
        overflow: hidden;
        gap: 8px;
        p{
            color: var(--Portfolio-Black, #1F1F1F);
            text-align: center;
            /* Desktop/Poppins/Medium 12 */
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .col-lg-4{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 32px 16px;
    }
    @media only screen and (max-width: 769px) {
        .col-lg-4{
            grid-template-columns: repeat(2,1fr);
        }
        .profile-bg{
            background-size: 100% 108% !important;
        }
    }
    @media only screen and (max-width: 576px) {
        .col-lg-4{
            grid-template-columns: repeat(1,1fr);
            justify-items: center;
        }
    }
    .classicBtn{
        display: flex;
        padding: 8px 16px;
        gap: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 96px;
        border: 1px solid var(--Portfolio-Grey-200, #8F8F8F);
        color: var(--Portfolio-Black, #1F1F1F);
        /* Desktop/Poppins/Light 16 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: auto;
        text-decoration: none;
    }
    .classicBtn:hover{
        background: var(--Portfolio-gradient) !important;
        color: #fff !important;
        border: 1px solid transparent !important;
        background-repeat: no-repeat !important;
    }
    .mac-img{
        margin: auto;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%,0%);
    }
    .tab-carosels{
        position: relative;
        .slick-slider{
            overflow: hidden;
        }
    }
    .mac-img-div{
        position: relative;
        margin-top: 41px;
        overflow: hidden;
        padding: 30px 0px;
    }
    .fade-down{
        position: absolute;
        bottom: -16px;
    }
    .tab-panels{
        display: grid;
        align-items: center;
        justify-content: center;
        justify-items: center;
        position: relative;
    }
    .tab-panels:focus-visible{
        outline: none;
    }
    .profile-links{
        max-width: 921px;
        height: 546px;
        padding: 24px 96px 40px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        margin: auto;
        height: 497px;
    }
    .profile-links::-webkit-scrollbar {
        width: 8px;
    }
    .profile-links::-webkit-scrollbar-track {
        background: transparent;        /* color of the tracking area */
      }
      
      .profile-links::-webkit-scrollbar-thumb {
        background-color: #8F8F8F;
        border-radius: 20px; 
      }
    .profile-bg{
        background: url(${require('./img/Elevating-cert.png')});
        background-repeat: no-repeat;
        background-position: center top;
        padding: 96px 0px;
        overflow: hidden;
        margin: 40px 0px;
    }
    .below-app{
        display: grid;
        max-width: 548px;
        margin: auto;
        margin-top: 24px;
        gap:4px;
        padding: 24px 0px;
        text-align: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        border-radius: 8px;
        background: var(--Portfolio-Grey-100, #ECECEC);
      .main-txt{
        color: var(--Portfolio-Black, #1F1F1F);
        text-align: center;
        /* Desktop/Poppins/Medium 12 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
       .addd-txt{
        color: var(--Portfolio-Grey-200, #8F8F8F);
        text-align: center;
        /* Desktop/Poppins/Medium 12 */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
       }
        .high-txt{
            text-align: center;
            /* Desktop/Poppins/Medium 12 */
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`; 

const SpotLight = styled.section`
.marquee {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 138px;
    width: 100%;
    border-radius: 22px;
    overflow: hidden;
    background: white;
    white-space: nowrap;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 440px) {
    .marquee {
      height: 50px;
    }
  }
  
  .marquee--inner {
    display: block;
    width: 200%;
    position: absolute;
    animation: marquee 24s linear infinite;
  }
  .marquee--inner:hover {
    animation-play-state: paused;
    color: red;
  }
  
  span {
    float: left;
    width: 50%;
    overflow: hidden;
  }
  
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
`


const Explore = styled.section`
    padding: 152px 0;
    padding-top: 0;
    background-color: var(--Portfolio-Black, #000);
    margin-top: -1px;
    .exlpore-sec{
        position: relative;
        overflow: hidden;
        padding: 45px 0px;
        .mac-img{
            position: absolute;
            top: 0;
            left: 50%;
            max-width: 930px;
            transform: translate(-50%, 0%);
        }
        .exlore-parent{
            // padding-left: 10vw;
            // display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            width: fit-content;
            overflow: hidden;
        }
        .explore-child{
            display: flex;
            position: relative;
            flex-wrap: nowrap;
            justify-content: center;
            white-space: nowrap;
            padding-left: 33vw;
            a{
                display: flex;
                width: 380px;
            }
        }
        @media only screen and (max-width: 767px) {
            .exclusive-scroller-parent{
                padding-left: 0px;
            }
            .Explore-Slider{
                display: grid;
            }
            .explore-child:nth-child(1){
                order: 2;
            }
            .explore-child{
                display: block;
                width: 100%;
                white-space: break-spaces;
                padding: 0px 15px;
                text-align: center;
            }
            .usx-grid{
                width: 50%;
                display: inline-block;
                a{
                    width: 100%;
                }
            }
        }
        @media only screen and (max-width: 440px) {
            .exclusive-scroller-parent{
                padding-left: 0px;
            }
            .explore-child{
                display: block;
                width: 100%;
                white-space: break-spaces;
                padding: 0px 15px;
                text-align: center;
            }
            .Explore-Slider{
                display: grid;
            }
            .explore-child:nth-child(1){
                order: 2;
            }
            .usx-grid{
                width: 50%;
                display: inline-block;
                a{
                    width: 100%;
                }
            }
        }
        
    }
`;

const Exclusive = styled.section`
        padding: 152px 0;
        background-color: var(--Portfolio-Black, #000);
        margin-top: -1px;
        position: relative;
        ::before{
            content: "";
        }
        .exclusive-bg{
            margin-top: 41px;
            padding: 60px 0px;
            position: relative;
            overflow: hidden;
        }
        .fade-down{
            position: absolute;
            bottom: 140px;
            left: 0;
            width: 100%;
        }
        .mac-img{
            position: absolute;
            top: 0;
            height: 100%;
            max-width: 930px;
            left: 50%;
            transform: translate(-50%, 0%);
        }
        .exclusive-scroller-parent{
            position: relative;
            width: 100%;
            padding-left: 10vw;
        }
       
        .exclusive-scroll{
            white-space: nowrap;
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            align-items: center;
            z-index: 9;
            width: fit-content;
            a{
                // position: relative;
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                min-width: 500px;
            }
            img{
                // width: 100%
            }
        }
        @media only screen and (max-width: 767px) {
            .exclusive-scroller-parent{
                padding-left: 0px;
            }
            .exclusive-scroll{
                display: block;
                width: 100%;
                white-space: break-spaces;
                padding: 0px 15px;
                text-align: center;
            }
            .usx-grid{
                width: 100%;
                display: inline-block;
            }
            .ux-aps{
                min-width: 100% !important;
            }
        }
        @media only screen and (max-width: 440px) {
            .exclusive-scroller-parent{
                padding-left: 0px;
            }
            .exclusive-scroll{
                display: block;
                width: 100%;
                white-space: break-spaces;
                padding: 0px 15px;
                text-align: center;
            }
            .usx-grid{
                width: 50%;
                display: inline-block;
            }
            .ux-aps{
                min-width: 50% !important;
            }
        }
        .usx-grid{
            flex: 0 0 20.33% !important;
        }
`

const MacBackground = styled.section`
    padding: 152px 0;
    background-color: var(--Portfolio-Black, #000);
    margin-top: -1px;
    position: relative;

    .mac-img-div{
        position: relative;
        margin-top: 41px;
        overflow: hidden;
        padding: 30px 0px;
    }
    .fade-down{
        position: absolute;
        bottom: -16px;
    }
    .mac-img-div .mac-img{
        position: absolute;
        top: 0px;
        overflow: hidden;
        height: 100%;
    }
    .usx-grid{
        overflow: hidden;
        padding: 12px;
        position: relative;
    }
    .ux-section{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        width: 100%;
        overflow: hidden;
    }
    .usx-grid .ux-aps{
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        border-radius: 6px;
        border: 2px solid var(--Portfolio-Black, #1F1F1F);
        border-top-width: 15px;
        box-shadow: 0px 0px 10.27px 0px rgba(0, 0, 0, 0.05);
        display: block;
        border-image-slice: 1;
    }
    .view-txt{
        // width: 95.5%;
        // height: 89%;
        // display: grid;
        // position: absolute;
        // top: 26px;
        // left: 14px;
        // opacity: 0.8;
        // background: var(--Portfolio-Black, #1F1F1F);
        // color: #fff;
        // align-items: center;
        // justify-content: center;
        // color: var(--Portfolio-White, #FFF);
        // text-align: center;
        // font-family: Poppins;
        // font-size: 56px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: normal;
        // visibility: hidden;
    }
    .usx-grid .ux-aps::after{
        content: "";
        background: url(${require('./img/Traffic_Lights.png')}) no-repeat;
        position: absolute;
        position: absolute;
        top: 17px;
        left: 25px;
        width: 27px;
        height: 9px;
        background-size: 100% 100%;
        z-index: 999;
    }
    .header-text{
        content: "iConnect";
        color: rgba(235, 235, 245, 0.30);
        text-align: center;
        font-family: Poppins;
        font-size: 6.159px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        position: absolute;
        top: 17px;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .usx-grid .ux-aps img{
        transition: all 0.5s ease-in-out;
        overflow: hidden;
    }
    .usx-grid .ux-aps:hover img{
        scale: 1.1;
        overflow: hidden;
        object-position: 55%;
        transform: scale(1.1) translate(0, 5%);
    }
    .usx-grid .ux-aps:hover .view-txt{
        visibility: visible;
    }
    .usx-grid .ux-aps:hover{
        border-image-source: linear-gradient(90deg, #FA0006 0%, #2300F0 100%);
    }
    @media only screen and (max-width: 769px) {
        .ux-section{
            grid-template-columns: repeat(1,1fr);
        }
    }
`

const ParrallaxBanner = Styled.section`
    padding: 156px 0;
    margin-top: -1px;
    background-size: cover;
    position: relative;
    background-size: 100% 100%;
    overflow: hidden;
    background-color: var(--Portfolio-Black, #000);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        .bg-parralex{
            position: absolute;
            top: 0;
            left: -2%;
            width: 100%;
            height: 100%;
            overflow: hidden;
            scale: 1.5;
        }
    .fade-up{
        width: 100%;
        position: absolute;
        top: 0;
    }
    .fade-down{
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .parallax-hover-sec .hover-scroller{
        // padding-top: 200px;
    }
    .parallax-hover-sec .hover-scroller.midval-div{
        // padding-top: 60px;
    }
    .parallax-hover-sec .center-animation{
        padding-bottom: 0px;
    }
    .parallax-hover-sec .center-animation p{
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 32px;
        word-break: break-all;
        line-height: 140%;
    }
    @media only screen and (max-width: 767px) {
        .parallax-hover-sec .center-animation p{
            font-size: 16px;
            line-height: 120%;
        }
    }
    .parallax-hover-sec{
        display: flex;
        align-items: self-start;
        justify-content: center;
        gap: 150px;
    }
    @media only screen and (max-width: 767px) {
    .parallax-hover-sec{
        // display: block;
        gap: 7px;
    }
    }
    .hover-scroller {
        color: #fff;
        position: relative;
        padding: 56px 0px;
        img{
            position: absolute;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;
            transition: all 0.3s ease;
            width: 0;
            height: 0;
            border-radius: 12px;
        }
        img:hover{
            width: 100%;
            height: 100%;
        }
    }
    @media only screen and (max-width: 769px) {
        .hover-scroller{
            padding: 16px 0px;
            display: inline-block;
            width: 100%;
            h4{
                font-size: 32px !important;
                margin: 8px 0px;        
            }
        }
    }
    @media only screen and (max-width: 767px){
        .hover-scroller{
            padding: 16px 0px;
            display: inline-block;
            width: 100%;
        }
        img{
            display: none;
        }
    }
    .hover-scroller:hover img{
        width: 100%;
        height: 100%;
    }
    .hover-scroller p{
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        text-align: center;
    }
    .hover-scroller h4{
        font-family: 'Poppins', sans-serif;
        font-size: 6vw;
        line-height: 100%;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        overflow: hidden;
    }
`;

const StyledBanner = Styled.div`
    height: 100vh;
    background: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-sec-pdd{
        padding-left:10vw;
    }
`
const BannerText = Styled.h1`
    text-align: Left;
    font-family: 'Poppins', sans-serif;
    font-size: 13vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media only screen and (max-width: 767px) {
        font-size: 34px;
        line-height: 56px;
        letter-spacing: 0px;
    }
`;

const SmallText = Styled.h4`
    font-size: 56px;
    line-height: 84px;
    color: var(--Portfolio-White, #FFF);
    left: 0px;
    letter-spacing: normal;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 3000px;
    @media only screen and (max-width: 768px) {
        width: fit-content;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 26px;
    }
`;