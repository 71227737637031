import "./MasterCss.css";
import "./style.css"
import React,{useEffect,useState,useRef} from "react";
import Styled, { styled } from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Col,Row,Image,Button} from 'antd';
import {
    DoubleRightOutlined,
    ExportOutlined
  } from '@ant-design/icons';
  import AOS from 'aos';
  import 'aos/dist/aos.css';
import {message} from "antd";

const Innovation = () => { 
    AOS.init();
  const HeadContainer = useRef();
    const Slideer = useRef();   
    useEffect(() => {
        if(window.innerWidth > 768){
            const t2 = gsap.to(Slideer.current, {
                x: -(Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                duration: .1,
                ease: "none",
            });
            ScrollTrigger.create({
                trigger: HeadContainer.current,
                start : "top top",
                end: () => (Slideer.current.offsetWidth - HeadContainer.current.offsetWidth) * 1 + "px",
                pin: true,
                animation:t2,
                scrub:1,
                invalidateOnRefresh:true,
                // markers:true
      
            })            
            return () => {
                t2.revert();
            }
        }
  },[]);
      return (
        <>
        <div>
       <StyledBanner ref={HeadContainer}>
            <Row justify="center" className="sub-page-banner-sec">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='banner-sec-pdd'>
                    <BannerText className="sub-page-banner">Innovation</BannerText>
                <SmallText ref={Slideer}>
                    Check it out, In-Depth Exploration of My Innovative Achievements<span> 🚀</span>
                </SmallText>
                </Col>                
            </Row>
       </StyledBanner>
       </div>

        <section className="innovation-sec">        
       <div className="wrapper">
            <div className="content-warpper">
                <div className="content-wrapper-inner p_80 p_80_xxl p_70_xl p_15_xs col_2 col_2_xxl col_2_xl col_2_lg col_2_md col_1_sm g_25 a_i_c">
                    <div className="img-warpper">
                    <img src={require('./img/Breadcrumb_image_L2-1.png')} alt="Innovation" />
                    </div>
                    <div className="text-warpper">
                        <h4 style={{textAlign:"left"}}>
                        # Breadcrumbs
                        </h4>
                        <p>
                            <span>Vertical breadcrumbs</span> are a hierarchical navigation tool often displayed vertically on a webpage, providing a clear path for users to trace their steps back through the website's structure. These breadcrumbs typically indicate the user's location within the site's hierarchy and offer an organized way to navigate to previous levels or sections.
                        </p>
                    </div>
                </div>
            

            <div className="challenge-wrapper">
                <div className="challege-head">
                    <h4>
                        Challenge
                    </h4>
                    <p>
                        The <span>challenge with vertical breadcrumbs</span> arises when users create or open multiple levels. It becomes cumbersome for them to <span>scroll horizontally or stretch the panel to view the names</span>. I've encountered instances of this challenge in examples from <span>Figma, Microsoft apps, and various websites.</span>
                    </p>
                </div>
                <div className="challenge-wrapper-img">
                    <img src={require('./img/Breadcrumb_challenge_img.png')} style={{margin:"0 auto",width: '100%'}}/>
                </div>
                <p className="notetext">
                The images on the left and right are sourced from the internet, while the center image is a screenshot <br />
captured from the actual design.
                </p>
            </div>

            <div className="Solution-warrper">
                <div className="challege-head">
                    <h4>
                        Solution
                    </h4>
                    <p>
                    I've presented the solution in the video visual below, and I've also included screenshots for inspiration. Crucially, I <span>validated</span> this concept with the <span>CEO at Exalca,</span> and we successfully applied this approach to the <span>ARANxt project,</span> yielding <span>excellent results.</span> 
                    </p>
                </div>
                <div className="content-wrapper-inner p_80 p_80_xxl p_70_xl p_15_xs col_2 col_2_xxl col_2_xl col_2_lg col_2_md col_1_sm g_25 a_i_c">
                    <div className="img-warpper">
                        
                        <video 
                        style={{shadowOpacity: 1,shadowColor: '#fff'}}
                        width={"100%"} autoPlay muted loop 
                        src={require('./img/Breadcrumb _invention_1.mp4')}
                        type="video/mp4" 
                        controls={false} />
                    </div>
                <div className="text-warpper">
                        <p>
                        I devised this solution to allow users to <span>easily check</span> their location and current <span>selection</span>. By incorporating it into the top navigation, users can quickly identify where they are, and the design supports <span>clickable actions.</span> From a <span>user experience</span> perspective, I <span>eliminated</span> the need for <span>left padding</span> spacing in the parent-child hierarchy, creating <span>minimal spacing</span>. 
                        </p>
                        <br/>
                        <br/>
                        <p>
                        This design choice aims to <span>save time by avoiding horizontal scrolling or stretching to view </span> the full name of the layer. Additionally, for a more enhanced user experience, the details of the <span>last selected layer</span> are displayed inside the <span>border</span>.
                        </p>
                    </div>
                    
                </div>
            </div>

            <div className="bottom-gradient">
                <img src={require('./img/Breadcrumb_screenshot.png')} />
            </div>
            </div>
       </div>

       <div className="wrapper">
       <div className="content-warpper">
             <div className="content-wrapper-inner p_80 p_80_xxl p_70_xl p_15_xs col_2 col_2_xxl col_2_xl col_2_lg col_2_md col_1_sm g_25 a_i_c">
                   
                    <div className="text-warpper">
                        <h4 style={{textAlign:"left"}}>
                        #Carousels
                        </h4>
                        <p>
                            Carousels, often referred to as <span>sliders or slideshows, are dynamic components</span> on websites or applications that display a series of images or content in a rotating manner. They offer a visually engaging way to showcase multiple pieces of information within a confined space.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Users can typically navigate through the carousel by clicking or swiping, allowing them to view different items or messages sequentially. Carousels are commonly utilized in web design to highlight key features, promotions, or a variety of content in a compact and interactive format.
                        </p>
                    </div>
                    <div className="img-warpper">
                    <img src={require('./img/Breadcrumb_image_L2.png')} alt="Innovation"style={{margin: "auto"}} />
                    </div>
                </div>

                <div className="challenge-wrapper">
                <div className="challege-head">
                    <h4>
                        Challenge
                    </h4>
                    <p>
                    As the number of <span>slides increases</span> in carousels, the corresponding <span>dots</span> also <span>multiply</span>. This can pose challenges for <span>users</span> who wish to navigate based on their preferences. In the case of auto carousels, users might need to <span>wait,</span> and smaller dots may <span>hinder</span> the visibility of <span>clickable actions.</span>
                    <br/>
                    <br/>
                    I've identified a few instances below that illustrate this issue, sourced from <span>LinkedIn</span> and the internet.
                    </p>
                </div>
                <div className="challenge-wrapper-img remove-bf-af">
                    <img src={require('./img/Carousels_challenge_img.png')} style={{margin:"40px auto",width: '100%'}}/>
                </div>
                <p className="notetext">
                The image on the left is obtained from the internet, whereas the one on the right is a screenshot captured <br />
                directly from the LinkedIn design
                </p>
            </div>

            <div className="Solution-warrper">
                <div className="challege-head">
                    <h4>
                        Solution
                    </h4>
                    <p>
                        I've demonstrated the solution in the video visuals provided below, accompanied by screenshots for inspiration. It's noteworthy that I sought <span>validation</span> for this concept from the <span>Head of Design at Concentrix Catalyst.</span> Subsequently, we implemented this approach in the <span>Birla OPUS project, achieving remarkable success.</span>
                    </p>
                </div>
                <div className="content-wrapper-inner p_80 p_80_xxl p_70_xl p_15_xs col_2 col_2_xxl col_2_xl col_2_lg col_2_md col_1_sm g_25 a_i_c">
                    
                <div className="text-warpper">
                        <p>
                        In today's scenario, many users tend to <span>click</span> on the <span>inner dots</span> within carousels based on their preferences due to the presence of multiple dots. 
                        </p>
                        <br/>
                        <br/>
                        <p>
                        To address this, I've devised a solution involving <span>hover interaction.</span> When a user hovers over the carousel, all the <span>dots increase</span> in size uniformly, making it <span>easier</span> for them to click based on their <span>choices</span>
                        </p>
                    </div>

                    <div className="img-warpper">
                        
                        <video 
                        style={{shadowOpacity: 1,shadowColor: '#fff'}}
                        width={"100%"} autoPlay muted loop 
                        src={require('./img/Caurosel_interaction_inevntion2.mp4')}
                        type="video/mp4" 
                        controls={false} />
                    </div>
                    
                </div>
            </div>
            <div className="bottom-gradient">
                <img src={require('./img/Carousels_footer_screenshots.png')} />
            </div>
            </div>
       </div>
       </section>

       <section className="fun-facts">
            <Row justify="center" className="mac-sec">
                <Col span={19} xs={24} sm={24} md={13} lg={19} xl={19}>
                    <h3 class="section-heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    >Fun Facts</h3>
                    <h4 class="banner-sub-text black-gradeiant"
                     data-aos="fade-up"
                     data-aos-delay="100"
                     data-aos-duration="1000"
                     data-aos-easing="ease-in-out"
                     data-aos-mirror="true"
                     data-aos-once="true">Trust me, there's more to come</h4>
                </Col>
            </Row>
            <p
             data-aos="fade-up"
             data-aos-delay="150"
             data-aos-duration="1000"
             data-aos-easing="ease-in-out"
             data-aos-mirror="true"
             data-aos-once="true">
            At times, during my design process, it steers in a direction surpassing my expectations, providing complete self-satisfaction.
            </p>
       </section>
        </>
      )
};

export default Innovation;

const StyledBanner = Styled.div`
    height: 100vh;
    background: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-sec-pdd{
        padding-left:10vw;
    }
`
const BannerText = Styled.h1`
    text-align: Left;
    font-family: 'Poppins', sans-serif;
    font-size: 13vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media only screen and (max-width: 767px) {
        font-size: 34px;
        line-height: 56px;
        letter-spacing: 0px;
    }
`;

const SmallText = Styled.h4`
    font-size: 56px;
    line-height: 84px;
    color: var(--Portfolio-White, #FFF);
    left: 0px;
    letter-spacing: normal;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 3000px;
    @media only screen and (max-width: 768px) {
        width: fit-content;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 26px;
    }
`;