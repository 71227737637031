import React from 'react'
import {Col,Row,Image,Button} from 'antd';

const Footer = () => {
  return (
    <>
        <Row justify="center" className="footer-inner-section">
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="d_f a_i_c j_cent" style={{justifyContent:'left'}}>
            <ul className="footer-menu col_3 g_25" style={{justifyContent:'left',textAlign:'center'}}>
                <li>
                    <a href='https://www.instagram.com/raaj.ux/' target='_blank'>Instagram</a>
                </li>
                <li>
                    <a href='https://www.linkedin.com/in/rajasekar-a-product-designer/' target='_blank'>Linkedin</a>
                </li>
                <li>
                    <a href='https://www.behance.net/rjvj' target='_blank'>Behance</a>
                </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="d_f a_i_c j_cent" style={{justifyContent:'end'}}>
              <a className='raaj-mailid' href="mailto:hello@raajux.com">
                  hello@raajux.com
              </a>
            </Col>
        </Row>
    </>
  )
}

export default Footer
